import { Navigate, useLocation } from "react-router-dom";
import { useAuthenticationContext } from "../admin/authenticationContext";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useAuthenticationContext();
  const location = useLocation();

  if (!isLoggedIn && location.pathname !== "/login") {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isLoggedIn) {
    return null;
  }

  return children;
};
