import { PageTransition } from "../misc/pageTransition";
import { Logo, LogoVariant } from "../misc/logo";
import {
  AspectRatio,
  Box,
  Flex,
  Image,
  Link,
  Spacer,
  Text,
  Container,
  SimpleGrid,
} from "@chakra-ui/react";
import { useUserDataContext } from "../data/userDataContext";
import { SignOutLink } from "../misc/signOutLink";
import { SubHeading } from "../misc/subHeading";
import { MainHeading } from "../misc/mainHeading";

export const Home = () => {
  const { user, models, numberOfModelViews } = useUserDataContext();

  const allModels =
    models?.map((model) => ({
      ...model,
      views: numberOfModelViews?.[model.id] || 0,
    })) ?? [];

  const activeModels = allModels
    .filter((m) => !m.disabled)
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const numberOfSlots = user?.abo?.models || activeModels.length;
  const availableSlots = numberOfSlots - activeModels.length;

  // name can be null at the time of registering...
  // TODO: handle that earlier
  const firstName = user?.name?.split(" ")[0];

  return (
    <PageTransition>
      <Logo variant={LogoVariant.Big} />
      <MainHeading>Hallo {firstName}</MainHeading>
      <Text>
        Diese Startseite zeigt dir in einer kompakten Übersicht den Status
        deiner Modellplätze, Views und deinem aktuellen Abo.
        <br />
        <br />
        Falls du Fragen hast, darfst du dich gerne an unseren{" "}
        <Link href="https://3d-tool.ch/support">Support</Link> wenden.
      </Text>

      <SimpleGrid
        paddingTop={10}
        paddingBottom={10}
        columns={[1, 1, 2]}
        spacing={10}
      >
        <Container
          paddingLeft={"5vw"}
          paddingRight={"5vw"}
          textAlign={"center"}
          maxWidth={400}
        >
          <AspectRatio ratio={651 / 751}>
            <Image
              src={
                "https://3d-tool.ch/wp-content/uploads/2022/08/Grafikreich_Sascha-Heimberg_A.png"
              }
              alt="Sascha Heimberg"
              userSelect="none"
              pointerEvents="none"
            />
          </AspectRatio>

          <SubHeading>Sascha Heimberg</SubHeading>
          <Text>heimberg@grafikreich.ch</Text>
        </Container>
        <Container
          paddingLeft={"5vw"}
          paddingRight={"5vw"}
          textAlign={"center"}
          maxWidth={400}
        >
          <AspectRatio ratio={651 / 751}>
            <Image
              src={
                "https://3d-tool.ch/wp-content/uploads/2022/08/Grafikreich_Simon-Pauli_A.png"
              }
              alt="Simon Pauli"
              userSelect="none"
              pointerEvents="none"
            />
          </AspectRatio>

          <SubHeading>Simon Pauli</SubHeading>
          <Text>pauli@grafikreich.ch</Text>
        </Container>
      </SimpleGrid>

      <SubHeading>Dein Abonnement</SubHeading>

      <Box
        borderColor="whiteAlpha.900"
        overflow={"hidden"}
        borderWidth={1}
        borderRadius={5}
        marginBottom={10}
        paddingTop={2}
        paddingBottom={2}
        paddingLeft={5}
        paddingRight={5}
      >
        <Flex>
          <Box>Aktuelles Abo:</Box>
          <Spacer />
          <Box>«{user?.abo?.name}»</Box>
        </Flex>
        <Flex>
          <Box>Modellplätze:</Box>
          <Spacer />
          <Box>{numberOfSlots}</Box>
        </Flex>
        <Flex>
          <Box>Leere Plätze:</Box>
          <Spacer />
          <Box>{availableSlots}</Box>
        </Flex>
      </Box>

      {activeModels.length > 0 && (
        <>
          <SubHeading>Deine Modelle</SubHeading>
          <Box
            borderColor="whiteAlpha.900"
            overflow={"hidden"}
            borderWidth={1}
            borderRadius={5}
            marginBottom={10}
            paddingTop={2}
            paddingBottom={2}
            paddingLeft={5}
            paddingRight={5}
          >
            {activeModels.map((model) => (
              <Flex key={model.id}>
                <Text whiteSpace={"nowrap"}>{model.name}</Text>
                <Spacer />
                <Text>{model.views}</Text>
              </Flex>
            ))}
          </Box>
        </>
      )}

      <SignOutLink />
      <br />
      <br />
    </PageTransition>
  );
};
