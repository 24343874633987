import { chakra, Heading } from "@chakra-ui/react";

export const SubHeading = chakra(Heading, {
  baseStyle: {
    textTransform: "uppercase",
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 2,
  },
});

SubHeading.defaultProps = { ...Heading.defaultProps, size: "sm" };
