import icon from "../assets/img/models.svg";
import iconActive from "../assets/img/models_active.svg";

import { AspectRatio, Image } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { FunctionComponent } from "react";

interface LinkModelsProps {
  currentPosition: number | null;
  position: number;
}

export const LinkModels: FunctionComponent<LinkModelsProps> = ({
  currentPosition,
  position,
}) => {
  const location = useLocation();
  const active = location.pathname === "/models";

  return (
    <Link to="/models" state={{ current: currentPosition, position: position }}>
      <AspectRatio ratio={18 / 25} width={50}>
        <Image
          src={active ? iconActive : icon}
          alt="Modelle"
          userSelect="none"
        />
      </AspectRatio>
    </Link>
  );
};
