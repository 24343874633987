import { Input } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface StyledTextInputProps {
  type: "text" | "email";
  placeholder?: string;
  registration: UseFormRegisterReturn;
  autoComplete?: string;
}

export const StyledTextInput: FunctionComponent<StyledTextInputProps> = ({
  type,
  placeholder,
  registration,
  autoComplete,
}) => {
  return (
    <Input
      type={type}
      colorScheme="whiteAlpha"
      errorBorderColor="red.400"
      focusBorderColor="yellow.500"
      placeholder={placeholder}
      autoComplete={autoComplete}
      borderColor="whiteAlpha.900"
      _hover={{
        borderColor: "whiteAlpha.900",
      }}
      {...registration}
    />
  );
};
