import { Textarea } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface StyledTextAreaProps {
  placeholder?: string;
  registration: UseFormRegisterReturn;
}

export const StyledTextArea: FunctionComponent<StyledTextAreaProps> = ({
  placeholder,
  registration,
}) => {
  return (
    <Textarea
      colorScheme="whiteAlpha"
      errorBorderColor="red.400"
      focusBorderColor="yellow.500"
      placeholder={placeholder}
      borderColor="whiteAlpha.900"
      _hover={{
        borderColor: "whiteAlpha.900",
      }}
      {...registration}
    />
  );
};
