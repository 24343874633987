import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { MainHeading } from "../../misc/mainHeading";
import { PageTransition } from "../../misc/pageTransition";
import { Box, Button, Center, Link, Text, useToast } from "@chakra-ui/react";
import { Logo, LogoVariant } from "../../misc/logo";
import { useUserDataContext } from "../../data/userDataContext";
import { ModelForm } from "./modelForm";
import { LoadingPlaceholder } from "../../misc/loadingPlaceholder";
import { deleteObject, ref } from "firebase/storage";
import { useFirestore, useStorage } from "reactfire";
import { deleteDoc, doc } from "firebase/firestore";
import { ChevronLeftIcon } from "@chakra-ui/icons";

export const ModelEdit = () => {
  const { models, selectedUserId, updateUserData } = useUserDataContext();
  let { id } = useParams();

  const navigate = useNavigate();
  const firestore = useFirestore();
  const storage = useStorage();
  const toast = useToast();

  if (!models) {
    return <LoadingPlaceholder />;
  }

  const model = models?.find((model) => model.id === id);

  if (!model) {
    return (
      <PageTransition>
        <Logo variant={LogoVariant.Big} />
        <Box textAlign={"center"}>
          <MainHeading>Model nicht gefunden</MainHeading>
          <Text>
            Die URL ist leider ungültig oder das AR-Modell existiert nicht mehr.
          </Text>
        </Box>
      </PageTransition>
    );
  }

  const deleteModel = async () => {
    // remove glb file
    if (model.glb) {
      try {
        await deleteObject(
          ref(storage, `contents/${selectedUserId}/${model.glb.file}`)
        );
      } catch {
        return toast({
          title: "Fehler",
          description: `${model.glb.name} konnte nicht gelöscht werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // remove usdz file
    if (model.usdz) {
      try {
        await deleteObject(
          ref(storage, `contents/${selectedUserId}/${model.usdz.file}`)
        );
      } catch {
        return toast({
          title: "Fehler",
          description: `${model.usdz.name} konnte nicht gelöscht werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // remove png file
    if (model.png) {
      try {
        await deleteObject(
          ref(storage, `contents/${selectedUserId}/${model.png.file}`)
        );
      } catch {
        return toast({
          title: "Fehler",
          description: `${model.png.name} konnte nicht gelöscht werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // delete model
    try {
      await deleteDoc(doc(firestore, `models/${model.id}`));
    } catch {
      return toast({
        title: "Fehler",
        description: "Modell konnte nicht bearbeitet werden.",
        status: "error",
        position: "top",
      });
    }

    await updateUserData();
    navigate("/models");
    toast({
      description: "Das Modell wurde gelöscht.",
      status: "success",
      position: "top",
    });
  };

  return (
    <PageTransition>
      <Box position={"relative"}>
        <Link
          as={RouterLink}
          to="/models"
          position={"absolute"}
          top={"50%"}
          marginTop={"-36px"}
        >
          <ChevronLeftIcon color="yellow.500" width={"48px"} height={"72px"} />
        </Link>
        <Logo variant={LogoVariant.Small} />
      </Box>
      <MainHeading>Model bearbeiten</MainHeading>
      <Text>
        Um dein AR-Modell auf möglichst vielen Devices darstellen zu können
        benötigt es zwei verschiedene Dateiformate. Das Vorschaubild ermöglicht
        dir eine zusätzliche Optimierung des ersten Eindrucks.
      </Text>

      <br />
      <br />

      <ModelForm model={model} />

      <br />
      <br />

      <Center>
        <Button
          variant={"link"}
          color={"red.700"}
          size={"sm"}
          onClick={() => {
            if (window.confirm(`Soll das Modell wirklich gelöscht werden?`)) {
              deleteModel();
            }
          }}
        >
          3D-Modell löschen
        </Button>
      </Center>
      <br />
      <br />
    </PageTransition>
  );
};
