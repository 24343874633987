import { Box, IconButton, Input, InputRightElement } from "@chakra-ui/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { FunctionComponent } from "react";
import { DeleteIcon } from "@chakra-ui/icons";

interface StyledFileInputProps {
  placeholder?: string;
  acceptedFileTypes?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  registration: UseFormRegisterReturn;
  value?: FileList | null;
  clearFn: () => void;
}

export const StyledFileInput: FunctionComponent<StyledFileInputProps> = ({
  placeholder,
  acceptedFileTypes,
  isDisabled,
  registration,
  clearFn,
  value,
}) => {
  const file = value && value.length > 0 ? value[0] : null;
  const name = file?.name || (value !== null ? placeholder : "\u00a0");

  return (
    <>
      <Box as="label" width={"100%"}>
        <Input
          as="div"
          display={"flex"}
          alignItems={"center"}
          colorScheme="whiteAlpha"
          errorBorderColor="red.400"
          focusBorderColor="yellow.500"
          placeholder={placeholder}
          borderColor="whiteAlpha.900"
          _hover={{
            borderColor: "whiteAlpha.900",
          }}
        >
          {name}
        </Input>
        <input
          type="file"
          accept={acceptedFileTypes}
          style={{
            opacity: "0",
            position: "absolute",
            top: "5px",
            left: "5px",
            right: "40px",
            bottom: "5px",
          }}
          {...registration}
        ></input>
        <InputRightElement>
          <IconButton
            variant={"unstyled"}
            borderColor={"whiteAlpha.900"}
            borderRadius={2}
            borderLeftWidth={1}
            color="yellow.500"
            aria-label={`${placeholder} löschen`}
            icon={<DeleteIcon />}
            isDisabled={isDisabled}
            onClick={() => clearFn()}
          />
        </InputRightElement>
      </Box>
    </>
  );
};
