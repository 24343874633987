import { UserData } from "../data/userDataContext";

export const formatSubscriptionCost = (user: UserData): string => {
  if (!user.abo) {
    return "-";
  }

  if (user.abo.monthly) {
    return `${user.abo.monthly} CHF / Monat`;
  }

  if (user.abo.yearly) {
    return `${user.abo.yearly} CHF / Monat`;
  }

  return "-";
};
