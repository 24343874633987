import { collection, query, orderBy } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { Abonement } from "./userDataContext";

type AbonementWithId = Abonement & { id: string };

export const useAbos = () => {
  const firestore = useFirestore();
  const aboCollection = collection(firestore, "abos");
  const aboQuery = query(aboCollection, orderBy("models", "asc"));
  const { data: abos } = useFirestoreCollectionData(aboQuery, {
    idField: "id",
  });

  return { abos: (abos as AbonementWithId[]) || [] };
};
