import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface StyledTextInputProps {
  placeholder?: string;
  registration: UseFormRegisterReturn;
}

export const StyledNumberInput: FunctionComponent<StyledTextInputProps> = ({
  placeholder,
  registration,
}) => {
  return (
    <NumberInput
      colorScheme="whiteAlpha"
      errorBorderColor="red.400"
      focusBorderColor="yellow.500"
      borderColor="whiteAlpha.900"
      _hover={{
        borderColor: "whiteAlpha.900",
      }}
      width={"100%"}
    >
      <NumberInputField placeholder={placeholder} {...registration} />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  );
};
