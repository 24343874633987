import { Box, Stack, Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { UserData } from "../../data/userDataContext";
import { StyledColorInput } from "../../form/styledColorInput";
import { StyledFileInput } from "../../form/styledFileInput";
import { StyledFontSelect } from "../../form/styledFontSelect";
import { StyledFormControl } from "../../form/styledFormControl";
import { StyledTextArea } from "../../form/styledTextArea";
import { StyledTextInput } from "../../form/styledTextInput";
import { ProfileDesignPreview } from "./profileDesignPreview";

export type ProfileDesignValues = {
  bgImg?: FileList | null;
  bgColor?: string;
  borderColor?: string;
  font?: string;
  logo?: FileList | null;
  mainColor?: string;
  text?: string;
  textColor?: string;
  url?: string;
};

interface ProfileDesignFormProps {
  register: UseFormRegister<ProfileDesignValues>;
  resetField: UseFormResetField<ProfileDesignValues>;
  setValue: UseFormSetValue<ProfileDesignValues>;
  getValues: UseFormGetValues<ProfileDesignValues>;
  watch: UseFormWatch<ProfileDesignValues>;
  isLoading: boolean;
  user: UserData;
}

export const ProfileDesignForm: FunctionComponent<ProfileDesignFormProps> = ({
  register,
  resetField,
  setValue,
  getValues,
  watch,
  isLoading,
  user,
}) => {
  // the admin user does not have a design (e.g. admin)
  if (!user.design) {
    return (
      <Text size="xs" color="whiteAlpha.500">
        Keine Daten
      </Text>
    );
  }

  console.log("UDPATE");

  return (
    <Stack spacing={3} position={"relative"}>
      <ProfileDesignPreview
        userId={user.id}
        bgImg={user.design.bgImg?.file}
        logo={user.design.logo?.file}
        getValues={getValues}
      />

      <Box maxWidth={["100%", "100%", "60%"]}>
        <StyledFormControl
          title="Logo (jpg/png)"
          input={
            <StyledFileInput
              placeholder={user.design.logo?.name ?? "\u00a0"}
              acceptedFileTypes={"image/*"}
              registration={register("logo")}
              clearFn={() => {
                resetField("logo");
                setValue("logo", null);
              }}
              value={watch("logo")}
            />
          }
          //isInvalid={hasError}
          isDisabled={isLoading}
          //isRequired
        />
      </Box>

      <Box maxWidth={["100%", "100%", "60%"]}>
        <StyledFormControl
          title="Hintergrundbild (jpg/png)"
          input={
            <StyledFileInput
              placeholder={user.design.bgImg?.name ?? "\u00a0"}
              registration={register("bgImg")}
              clearFn={() => {
                resetField("bgImg");
                setValue("bgImg", null);
              }}
              value={getValues("bgImg")}
            />
          }
          isDisabled={isLoading}
        />
      </Box>

      <Box maxWidth={["100%", "100%", "60%"]}>
        <StyledFormControl
          title="Hintergrundfarbe"
          input={
            <StyledColorInput
              registration={register("bgColor")}
              setValue={(value) => setValue("bgColor", value)}
              getValue={() => getValues("bgColor")}
            />
          }
          //   isInvalid={hasError}
          isDisabled={isLoading}
          //   isRequired
        />
      </Box>

      <Box maxWidth={["100%", "100%", "60%"]}>
        <StyledFormControl
          title="Vordergrundfarbe"
          input={
            <StyledColorInput
              registration={register("mainColor")}
              setValue={(value) => setValue("mainColor", value)}
              getValue={() => getValues("mainColor")}
            />
          }
          //   isInvalid={hasError}
          isDisabled={isLoading}
          //   isRequired
        />
      </Box>

      <Box maxWidth={["100%", "100%", "60%"]}>
        <StyledFormControl
          title="Farbakzent (Rahmen, Start-Button)"
          input={
            <StyledColorInput
              registration={register("borderColor")}
              setValue={(value) => setValue("borderColor", value)}
              getValue={() => getValues("borderColor")}
            />
          }
          //   isInvalid={hasError}
          isDisabled={isLoading}
        />
      </Box>

      <Box maxWidth={["100%", "100%", "60%"]}>
        <StyledFormControl
          title="Textfarbe"
          input={
            <StyledColorInput
              registration={register("textColor")}
              setValue={(value) => setValue("textColor", value)}
              getValue={() => getValues("textColor")}
            />
          }
          //   isInvalid={hasError}
          isDisabled={isLoading}
        />
      </Box>

      <StyledFormControl
        title="Schrift"
        input={
          <StyledFontSelect
            name="font"
            setValue={(value) => setValue("font", value)}
            registration={register("font")}
          />
        }
        //   isInvalid={hasError}
        isDisabled={isLoading}
      />

      <StyledFormControl
        title="Impressum (wird unterhalb vom Modellbeschrieb angezeigt)"
        input={<StyledTextArea registration={register("text")} />}
        //   isInvalid={hasError}
        isDisabled={isLoading}
      />

      <StyledFormControl
        title="URL"
        input={<StyledTextInput type="text" registration={register("url")} />}
        //   isInvalid={hasError}
        isDisabled={isLoading}
        //   isRequired
      />
    </Stack>
  );
};
