import {
  Box,
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Select,
  VStack,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { googleFonts } from "../misc/googleFonts";

interface StyledFontSelectProps {
  name: string;
  registration: UseFormRegisterReturn;
  setValue: (value: string) => void;
}

export const StyledFontSelect: FunctionComponent<StyledFontSelectProps> = ({
  registration,
  setValue,
}) => {
  // lazy load those heavy fonts...
  const [injectFonts, setInjectFonts] = useState<boolean>();
  if (injectFonts) {
    googleFonts.forEach((font) => {
      const link = document.createElement("link");
      link.setAttribute("rel", "stylesheet");
      link.setAttribute("href", font.href);
      document.head.appendChild(link);
    });
  }

  return (
    <Popover onOpen={() => setInjectFonts(true)}>
      <PopoverTrigger>
        <Box width={"100%"}>
          <Select
            pointerEvents={"none"}
            borderColor="whiteAlpha.900"
            _hover={{
              borderColor: "whiteAlpha.900",
            }}
            {...registration}
          >
            <option value={""}></option>
            {googleFonts.map(({ key, name }) => (
              <option key={key} value={name}>
                {name}
              </option>
            ))}
          </Select>
        </Box>
      </PopoverTrigger>
      <PopoverContent bgColor={"black"} minWidth={"50vw"}>
        <PopoverArrow bg="black" borderColor={"black"} />
        <>
          <VStack maxHeight={"200px"} overflow={"auto"}>
            {googleFonts.map(({ key, name }) => (
              <Button
                isFullWidth
                variant={"unstyled"}
                size={"md"}
                textAlign={"left"}
                paddingLeft={4}
                key={key}
                style={{ fontFamily: name }}
                onClick={() => setValue(name)}
              >
                {name}
              </Button>
            ))}
          </VStack>
        </>
      </PopoverContent>
    </Popover>
  );
};
