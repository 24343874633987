import { PageTransition } from "../../misc/pageTransition";
import { useAdminDataContext } from "../../admin/adminDataContext";
import { useParams } from "react-router-dom";
import { SubHeading } from "../../misc/subHeading";
import { MainHeading } from "../../misc/mainHeading";
import { ProfileAddressForm } from "../profile/profileAddressForm";
import {
  ProfileEmailForm,
  ProfilePassordForm,
} from "../profile/profileAccountForm";
import { ProfileDesignForm } from "../profile/profileDesignForm";
import {
  Box,
  Text,
  Button,
  Center,
  Checkbox,
  Stack,
  Alert,
  AlertDescription,
  Link,
  useToast,
} from "@chakra-ui/react";
import { SignOutLink } from "../../misc/signOutLink";
import { FunctionComponent } from "react";
import { UserData, useUserDataContext } from "../../data/userDataContext";
import { useProfileForm } from "../profile/useProfileForm";
import {
  useActivateUser,
  useAdminSaveUser,
  useDeActivateUser,
} from "../../data/functions";
import { NotFound } from "../error-notfound";
import { CustomerDetailAbo } from "../profile/customerDetailAbo";

export const CustomerDetail = () => {
  const { users } = useAdminDataContext();
  let { id } = useParams();

  if (id === "0") {
    // add customer
    return <ProfileForm user={null} />;
  }

  const user = users.find((user) => user.id === id);

  if (!user) {
    return <NotFound />;
  }

  // edit customer
  return <ProfileForm user={user} />;
};

const ProfileForm: FunctionComponent<{ user: UserData | null }> = ({
  user,
}) => {
  const saveDataAction = useAdminSaveUser();
  const activateUser = useActivateUser();
  const deActivateUser = useDeActivateUser();
  const toast = useToast();
  const { updateUserData } = useUserDataContext();
  const {
    register,
    resetField,
    getValues,
    watch,
    setValue,
    control,
    handleSubmit,
    onSubmit,
    setIsLoading,
    isLoading,
    registrations,
  } = useProfileForm({
    user,
    saveDataAction,
  });

  const deActivate = async (userId: string) => {
    setIsLoading(true);
    try {
      await deActivateUser(userId);
      await updateUserData();
    } catch (e) {
      toast({
        title: "Fehler",
        description: "Der Kunde konnte nicht deaktiviert werden",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const activate = async (userId: string) => {
    setIsLoading(true);
    try {
      await activateUser(userId);
      await updateUserData();
    } catch (e) {
      toast({
        title: "Fehler",
        description: "Der Kunde konnte nicht deaktiviert werden",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageTransition>
      <MainHeading textAlign={"center"}>Kunde</MainHeading>

      {user && user.disabled && (
        <Box>
          <Alert status="warning" variant="solid" flexDirection="column">
            <AlertDescription textAlign={"center"}>
              <Text>Der Kunde wurde archiviert</Text>
              <Button
                onClick={() => activate(user.id)}
                isLoading={isLoading}
                isActive={!isLoading}
                loadingText="Wird aktiviert..."
                marginTop={2}
                size={"sm"}
              >
                Aktivieren
              </Button>
            </AlertDescription>
          </Alert>
        </Box>
      )}

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <SubHeading>Kunde</SubHeading>
        <ProfileAddressForm register={register} isLoading={isLoading} />

        <SubHeading>Login</SubHeading>
        <Stack spacing={3}>
          <ProfileEmailForm
            registration={registrations.email}
            autoComplete={`new-email`}
            isLoading={isLoading}
          />
          <ProfilePassordForm
            registration={registrations.password}
            autoComplete={`new-password`}
            isLoading={isLoading}
          />
        </Stack>

        <CustomerDetailAbo
          registration={register("aboId")}
          numberOfModelsRegistration={register("aboNumberOfModels")}
          isLoading={isLoading}
          control={control}
        />

        <Checkbox
          marginTop={4}
          disabled={isLoading}
          colorScheme="yellow"
          borderColor="yellow.500"
          defaultChecked={user?.abo?.demo}
          {...register("demo")}
        >
          Demo Abo
        </Checkbox>

        <br />

        {user && (
          <>
            <SubHeading>Design</SubHeading>
            <ProfileDesignForm
              user={user}
              isLoading={isLoading}
              getValues={getValues}
              register={register}
              watch={watch}
              resetField={resetField}
              setValue={setValue}
            />

            <br />
          </>
        )}

        <Center>
          <Button
            isLoading={isLoading}
            isActive={!isLoading}
            loadingText="Wird gespeichert..."
            type="submit"
          >
            Speichern
          </Button>
        </Center>

        {user && !user.disabled && (
          <>
            <br />
            <Center>
              <Link onClick={() => deActivate(user.id)}>Archivieren</Link>
            </Center>
          </>
        )}
      </form>

      <br />
      <br />
      <SignOutLink />

      <br />
      <br />
    </PageTransition>
  );
};
