import { Center, Link } from "@chakra-ui/react";
import { useAuth } from "reactfire";

export const SignOutLink = () => {
  const auth = useAuth();
  return (
    <Center>
      <Link onClick={() => auth.signOut()}>Abmelden</Link>
    </Center>
  );
};
