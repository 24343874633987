import { ComponentWithAs, Heading, HeadingProps } from "@chakra-ui/react";

export const MainHeading: ComponentWithAs<"h1", HeadingProps> = ({
  children,
  ...props
}) => (
  <Heading
    as="h1"
    size={"lg"}
    fontWeight={300}
    marginBottom={"1.5rem"}
    {...props}
  >
    {children}
  </Heading>
);
