import { Text } from "@chakra-ui/react";
import { Logo, LogoVariant } from "../misc/logo";
import { MainHeading } from "../misc/mainHeading";
import { PageTransition } from "../misc/pageTransition";

export function NotFound() {
  return (
    <PageTransition>
      <Logo variant={LogoVariant.Big} />
      <MainHeading textAlign={"center"}>Ooops!</MainHeading>
      <Text textAlign={"center"}>
        Die URL ist leider ungültig oder die Seite existiert nicht mehr.
      </Text>
    </PageTransition>
  );
}
