import { PageTransition } from "../../misc/pageTransition";
import { Logo, LogoVariant } from "../../misc/logo";
import {
  Text,
  Box,
  Flex,
  Spacer,
  IconButton,
  HStack,
  Center,
  Button,
} from "@chakra-ui/react";
import { useUserDataContext } from "../../data/userDataContext";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import { SignOutLink } from "../../misc/signOutLink";
import { SubHeading } from "../../misc/subHeading";
import { MainHeading } from "../../misc/mainHeading";
import { useNavigate } from "react-router-dom";
import { ModelShareButton } from "./modelShareButton";
import { OverflowText } from "../../misc/overflowText";

export const Models = () => {
  const { user, models, numberOfModelViews } = useUserDataContext();
  const navigate = useNavigate();

  const allModels =
    models?.map((model) => ({
      ...model,
      views: numberOfModelViews?.[model.id] || 0,
    })) ?? [];

  const activeModels = allModels
    .filter((m) => !m.disabled)
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const archivedModels = allModels
    .filter((m) => m.disabled)
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  const usedSlots = activeModels.length;
  const availableSlots = user?.abo?.models || usedSlots;
  const remainingSlots = availableSlots - usedSlots;

  return (
    <PageTransition>
      <Logo variant={LogoVariant.Small} />
      <MainHeading>Modelle</MainHeading>
      <Text>
        Diese Seite erlaubt dir die Bewirtschaftung deiner im Abo enthaltenen
        Modellplätze. Hier kannst du neue Modelle erfassen, Bestehende
        bearbeiten oder die gewünschten Link's zum Teilen generieren.
      </Text>

      <Box
        borderColor="whiteAlpha.900"
        overflow={"hidden"}
        borderWidth={1}
        borderRadius={5}
        marginTop={10}
        marginBottom={10}
        paddingTop={2}
        paddingBottom={2}
        paddingLeft={5}
        paddingRight={5}
      >
        <Flex>
          <Box>Belegte Plätze:</Box>
          <Spacer />
          <Box>{usedSlots} Stk.</Box>
        </Flex>
        <Flex>
          <Box>Freie Plätze:</Box>
          <Spacer />
          <Box>{remainingSlots} Stk.</Box>
        </Flex>
        <Flex>
          <Box>Archivierte Modelle:</Box>
          <Spacer />
          <Box>{archivedModels.length} Stk.</Box>
        </Flex>
      </Box>

      {activeModels.length > 0 && (
        <>
          <SubHeading>Deine Modelle</SubHeading>

          {activeModels.map((model) => (
            <Flex marginBottom={2} gap={1} key={model.id}>
              <IconButton
                onClick={() => navigate(`/models/edit/${model.id}`)}
                aria-label={`${model.name} bearbeiten`}
                variant={"outline"}
                color="yellow.500"
                borderColor="whiteAlpha.900"
                _hover={{
                  borderColor: "whiteAlpha.900",
                }}
                size={"sm"}
                icon={<EditIcon />}
              />
              <HStack
                href={`/v/${model.id}`}
                as={"a"}
                flex="1"
                fontSize={"sm"}
                paddingLeft={2}
                paddingRight={2}
                borderWidth={1}
                borderRadius={5}
                borderColor={"whiteAlpha.900"}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                <OverflowText>{model.name}</OverflowText>
                <Spacer />
                <Text>{model.views}</Text>
                <ViewIcon color="yellow.500" />
              </HStack>
              <ModelShareButton model={model} />
            </Flex>
          ))}
        </>
      )}

      {archivedModels.length > 0 && (
        <>
          <SubHeading>Modell-Archiv</SubHeading>

          {archivedModels.map((model) => (
            <Flex marginBottom={2} gap={1} key={model.id}>
              <IconButton
                onClick={() => navigate(`/models/edit/${model.id}`)}
                aria-label={`${model.name} bearbeiten`}
                variant={"outline"}
                color="yellow.500"
                borderColor="whiteAlpha.900"
                _hover={{
                  borderColor: "whiteAlpha.900",
                }}
                size={"sm"}
                icon={<EditIcon />}
              />
              <HStack
                flex="1"
                fontSize={"sm"}
                paddingLeft={2}
                paddingRight={2}
                borderWidth={1}
                borderRadius={5}
                borderColor={"whiteAlpha.900"}
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                <OverflowText>{model.name}</OverflowText>
                <Spacer />
                <Text>{model.views}</Text>
                <ViewIcon color="yellow.500" />
              </HStack>
            </Flex>
          ))}
        </>
      )}

      {remainingSlots > 0 && (
        <>
          <br />
          <br />
          <Center>
            <Button onClick={() => navigate("/models/new")}>
              Neues 3D-Modell
            </Button>
          </Center>
        </>
      )}

      <br />
      <SignOutLink />
      <br />
      <br />
    </PageTransition>
  );
};
