import { Box, HStack, Spacer } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useAdminDataContext } from "./adminDataContext";
import { StyledSelectInput } from "../form/styledSelectInput";
import { useUserDataContext } from "../data/userDataContext";

export const AdminBar: FunctionComponent = () => {
  const { users } = useAdminDataContext();
  const { selectedUserId, selectUserId } = useUserDataContext();

  const usersFilteredAndSorted = users
    .filter((user) => !user.disabled)
    .sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return (
    <Box borderTopColor={"yellow.500"} borderTopWidth={1}>
      <HStack
        paddingBottom={2}
        paddingTop={2}
        margin={"auto"}
        maxWidth={["100%", "100%", "100%", "960px", "1152px"]}
      >
        <Spacer />
        <StyledSelectInput
          value={selectedUserId}
          onChangeHandler={(userId) => selectUserId?.(userId)}
          maxWidth={250}
          size={"sm"}
        >
          {usersFilteredAndSorted.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </StyledSelectInput>
        <Spacer />
      </HStack>
    </Box>
  );
};
