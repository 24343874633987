import { Box, Container, Text } from "@chakra-ui/react";
import { FunctionComponent, useEffect, useState } from "react";
import { UseFormGetValues } from "react-hook-form";
import { nl2br } from "../../misc/nl2br";
import { ProfileDesignValues } from "./profileDesignForm";

const BASE_URL =
  "https://storage.googleapis.com/app-3d-tool.appspot.com/contents";

interface ProfileDesignFormProps {
  userId: string;
  bgImg: string | undefined;
  logo: string | undefined;
  getValues: UseFormGetValues<ProfileDesignValues>;
}

export const ProfileDesignPreview: FunctionComponent<
  ProfileDesignFormProps
> = ({ userId, bgImg, logo, getValues }) => {
  const backgroundImage = bgImg ? `${BASE_URL}/${userId}/${bgImg}` : undefined;

  const [borderColor, setBorderColor] = useState<string>();
  const [bgColor, setBgColor] = useState<string>();
  const [mainColor, setMainColor] = useState<string>();
  const [textColor, setTextColor] = useState<string>();
  const [text, setText] = useState<string>();

  useEffect(() => {
    const interval = setInterval(() => {
      setBorderColor(getValues("borderColor"));
      setBgColor(getValues("bgColor"));
      setMainColor(getValues("mainColor"));
      setTextColor(getValues("textColor"));
      setText(getValues("text"));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getValues]);

  return (
    <Box
      bgColor={bgColor}
      backgroundSize={"cover"}
      backgroundPosition={"center"}
      bgImage={backgroundImage}
      display={["none", "none", "block"]}
      borderColor="whiteAlpha.900"
      overflow={"hidden"}
      borderWidth={1}
      borderRadius={5}
      position={"absolute"}
      width={"calc(40% - 20px)"}
      height={"410px"}
      top={"35px"}
      right={0}
    >
      <Container
        height={"85%"}
        overflow={"hidden"}
        position={"relative"}
        bg={mainColor}
        borderStyle={"solid"}
        borderWidth={"0 4px 4px 4px"}
        borderRadius={"0 0 50px 50px"}
        borderColor={borderColor}
        padding={"1.5rem"}
        maxWidth={"70%"}
      >
        {logo && (
          <img
            src={`${BASE_URL}/${userId}/${logo}`}
            alt={""}
            style={{ maxHeight: "15%", margin: "0 auto 1.5rem auto" }}
          />
        )}

        <Box color={textColor} margin={"auto"} maxWidth={"80%"}>
          {text && (
            <Text paddingTop={10} fontSize={"70%"}>
              {nl2br(text)}
            </Text>
          )}
        </Box>
      </Container>
    </Box>
  );
};
