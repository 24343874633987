import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import { RequireAuth } from "./misc/requireAuth";
import { ArModel } from "./pages/ar-model";
import { Costs } from "./pages/costs";
import { CustomerDetail } from "./pages/admin/customerDetail";
import { Customers } from "./pages/admin/customers";
import { NotFound } from "./pages/error-notfound";
import { Home } from "./pages/home";
import { Login } from "./pages/login";
import { ModelEdit } from "./pages/models/modelEdit";
import { ModelNew } from "./pages/models/modelNew";
import { Models } from "./pages/models/models";
import { Profile } from "./pages/profile/profile";
import { Register } from "./pages/register";
import { ResetPassword } from "./pages/reset-password";
import { useAuthenticationContext } from "./admin/authenticationContext";

export const AnimatedRoutes = () => {
  const location = useLocation();
  const { isAdmin } = useAuthenticationContext();

  return (
    <AnimatePresence initial={false}>
      <Routes location={location} key={location.pathname}>
        <Route path="login" element={<Login />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="register" element={<Register />} />
        <Route path="v/:id" element={<ArModel />} />
        <Route path="/">
          <Route
            index
            element={
              <RequireAuth>{isAdmin ? <Customers /> : <Home />}</RequireAuth>
            }
          />
          <Route
            path="customers"
            element={
              <RequireAuth>
                <Customers />
              </RequireAuth>
            }
          />
          <Route
            path="customers/:id"
            element={
              <RequireAuth>
                <CustomerDetail />
              </RequireAuth>
            }
          />
          <Route
            path="profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="models"
            element={
              <RequireAuth>
                <Models />
              </RequireAuth>
            }
          />
          <Route
            path="models/new"
            element={
              <RequireAuth>
                <ModelNew />
              </RequireAuth>
            }
          />
          <Route
            path="models/edit/:id"
            element={
              <RequireAuth>
                <ModelEdit />
              </RequireAuth>
            }
          />
          <Route
            path="costs"
            element={
              <RequireAuth>
                <Costs />
              </RequireAuth>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
