import { PageTransition } from "../../misc/pageTransition";
import { Logo, LogoVariant } from "../../misc/logo";
import { EditIcon } from "@chakra-ui/icons";
import { useUserDataContext } from "../../data/userDataContext";
import { LoadingPlaceholder } from "../../misc/loadingPlaceholder";
import { SignOutLink } from "../../misc/signOutLink";
import { SubHeading } from "../../misc/subHeading";
import { MainHeading } from "../../misc/mainHeading";
import { ProfileDesignForm } from "./profileDesignForm";
import { useState } from "react";
import {
  Box,
  Flex,
  Spacer,
  IconButton,
  Center,
  Text,
  Button,
  Stack,
} from "@chakra-ui/react";
import { ProfileEmailForm, ProfilePassordForm } from "./profileAccountForm";
import { ProfileAddressForm } from "./profileAddressForm";
import { useProfileForm } from "./useProfileForm";
import { useSaveUserData } from "../../data/functions";
import { OverflowBox } from "../../misc/overflowBox";

export const Profile = () => {
  const { user } = useUserDataContext();
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const saveDataAction = useSaveUserData();

  const {
    register,
    resetField,
    getValues,
    watch,
    setValue,
    handleSubmit,
    onSubmit,
    isLoading,
    registrations,
  } = useProfileForm({ user, saveDataAction });

  if (!user) {
    return <LoadingPlaceholder />;
  }

  return (
    <PageTransition>
      <Logo variant={LogoVariant.Small} />
      <MainHeading>Profil</MainHeading>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <SubHeading>Deine Angaben</SubHeading>
        <Box
          borderColor="whiteAlpha.900"
          overflow={"hidden"}
          borderWidth={1}
          borderRadius={5}
          marginBottom={10}
          paddingTop={2}
          paddingBottom={2}
          paddingLeft={5}
          paddingRight={5}
        >
          {user.company && (
            <Flex>
              <Text>{user.company}</Text>
              <Spacer />
              <IconButton
                aria-label={`Profil bearbeiten`}
                variant={"unstyled"}
                color="yellow.500"
                size={"xs"}
                onClick={() => setShowAddressForm(!showAddressForm)}
                icon={<EditIcon />}
              />
            </Flex>
          )}
          <Flex>
            <Text>{user.name}</Text>
            <Spacer />
            {!user.company && (
              <IconButton
                aria-label={`Profil bearbeiten`}
                variant={"unstyled"}
                color="yellow.500"
                size={"xs"}
                onClick={() => setShowAddressForm(!showAddressForm)}
                icon={<EditIcon />}
              />
            )}
          </Flex>
          <Text>{user.address}</Text>
          <Text>{user.plzcity}</Text>
          {user.phone && <Text>{user.phone}</Text>}

          {showAddressForm && (
            <ProfileAddressForm register={register} isLoading={isLoading} />
          )}
        </Box>

        <SubHeading>Dein Login</SubHeading>
        <Stack
          borderColor="whiteAlpha.900"
          overflow={"hidden"}
          borderWidth={1}
          borderRadius={5}
          marginBottom={10}
          paddingTop={2}
          paddingBottom={2}
          paddingLeft={5}
          paddingRight={5}
        >
          <Flex>
            <OverflowBox>Benutzername:</OverflowBox>
            <Spacer />
            <OverflowBox>{user.email}</OverflowBox>
            <IconButton
              aria-label={`Benutzernamen ändern`}
              variant={"unstyled"}
              color="yellow.500"
              size={"xs"}
              onClick={() => setShowEmailForm(!showEmailForm)}
              icon={<EditIcon />}
            />
          </Flex>

          {showEmailForm && (
            <ProfileEmailForm
              registration={registrations.email}
              autoComplete={`email-${Date.now()}`}
              isLoading={isLoading}
            />
          )}

          <Flex>
            <Box>Passwort:</Box>
            <Spacer />
            <Box>********</Box>
            <IconButton
              aria-label={`Passwort ändern`}
              variant={"unstyled"}
              color="yellow.500"
              size={"xs"}
              onClick={() => setShowPasswordForm(!showPasswordForm)}
              icon={<EditIcon />}
            />
          </Flex>

          {showPasswordForm && (
            <ProfilePassordForm
              registration={registrations.password}
              autoComplete={`password-${Date.now()}`}
              isLoading={isLoading}
            />
          )}
        </Stack>

        {user.abo?.designEditor && (
          <>
            <SubHeading>Dein Erscheinungsbild</SubHeading>
            <ProfileDesignForm
              user={user}
              isLoading={isLoading}
              getValues={getValues}
              register={register}
              watch={watch}
              resetField={resetField}
              setValue={setValue}
            />
          </>
        )}

        <br />

        <Center>
          <Button
            isLoading={isLoading}
            isActive={!isLoading}
            loadingText="Wird gespeichert..."
            type="submit"
          >
            Speichern
          </Button>
        </Center>
      </form>

      <br />
      <br />
      <SignOutLink />
      <br />
      <br />
    </PageTransition>
  );
};
