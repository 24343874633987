import { useToast } from "@chakra-ui/react";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useStorage } from "reactfire";
import { UserData, useUserDataContext } from "../../data/userDataContext";
import { ProfileFormValues } from "./profileFormValues";
import { v4 as uuidv4 } from "uuid";
import { HttpsCallableResult } from "firebase/functions";
import { UpsertableUserData } from "../../data/functions";
import { useNavigate } from "react-router-dom";

type SaveData = (
  userId: string | undefined,
  data: UpsertableUserData
) => Promise<HttpsCallableResult<{ ok: boolean; err?: string; uid?: string }>>;

export const useProfileForm = ({
  user,
  saveDataAction,
}: {
  user: UserData | null;
  saveDataAction: SaveData;
}) => {
  const toast = useToast();
  const storage = useStorage();
  const navigate = useNavigate();
  const { updateUserData } = useUserDataContext();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    resetField,
    getValues,
    watch,
    setValue,
    handleSubmit,
    control,
  } = useForm<ProfileFormValues>();

  useEffect(() => {
    if (user && !isLoading) {
      setValue("email", user.email);
      setValue("company", user.company);
      setValue("name", user.name);
      setValue("address", user.address);
      setValue("plzcity", user.plzcity);
      setValue("phone", user.phone);
      setValue("aboId", user.aboId);
      setValue("aboNumberOfModels", user.abo?.models);
      setValue("demo", user.abo?.demo);

      if (user.design) {
        setValue("font", user.design.font || "");
        setValue("textColor", user.design.textColor || "");
        setValue("borderColor", user.design.borderColor || "");
        setValue("mainColor", user.design.mainColor || "");
        setValue("bgColor", user.design.bgColor || "");
        setValue("text", user.design.text || "");
        setValue("url", user.design.url || "");
      }
    }
  });

  const onSubmit: SubmitHandler<ProfileFormValues> = async (data) => {
    setIsLoading(true);

    const updatedProfileData: {
      email?: string;
      password?: string;
      company?: string;
      name?: string;
      address?: string;
      plzcity?: string;
      phone?: string;
      aboChange?: boolean;
      aboId?: string;
      abo?: {
        demo?: boolean;
        designEditor?: boolean;
        widgets?: boolean;
        instance?: string;
        name?: string;
        monthly?: number;
        models?: number;
      };
      design: {
        font?: string;
        textColor?: string;
        borderColor?: string;
        mainColor?: string;
        bgColor?: string;
        text?: string;
        url?: string;
        logo?: { file: string; name: string } | null;
        bgImg?: { file: string; name: string } | null;
      };
    } = {
      email: data.email,
      company: data.company,
      name: data.name,
      address: data.address,
      plzcity: data.plzcity,
      phone: data.phone,
      design: {
        font: data.font,
        textColor: data.textColor,
        borderColor: data.borderColor,
        mainColor: data.mainColor,
        bgColor: data.bgColor,
        text: data.text,
        url: data.url,
        logo: user?.design?.logo,
        bgImg: user?.design?.bgImg,
      },
    };

    if (data.password) {
      updatedProfileData.password = data.password;
    }

    if (data.aboId !== user?.aboId) {
      updatedProfileData.aboId = data.aboId;
    }

    if (typeof data.aboChange === "boolean") {
      updatedProfileData.aboChange = data.aboChange;
    }

    if (typeof data.demo === "boolean" && data.demo !== user?.abo?.demo) {
      updatedProfileData.abo = { demo: data.demo };
    }

    // custom abo
    if (
      (data.aboId === "CUSTOM" || !data.aboId) &&
      user?.abo &&
      data.aboNumberOfModels
    ) {
      updatedProfileData.aboId = "CUSTOM";
      updatedProfileData.abo = {
        designEditor: user.abo.designEditor,
        instance: "tool",
        monthly: user.abo.monthly,
        models: data.aboNumberOfModels,
        widgets: user.abo.widgets,
        name: "CUSTOM",
      };
    }

    console.log(updatedProfileData, data.aboNumberOfModels);

    // remove logo file
    if (
      (data.logo === null || (data.logo && data.logo.length > 0)) &&
      user?.design?.logo
    ) {
      try {
        await deleteObject(
          ref(storage, `contents/${user.id}/${user.design.logo.file}`)
        );
        updatedProfileData.design.logo = null;
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${user.design.logo.name} konnte nicht gelöscht werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // upload logo file
    if (user && data.logo && data.logo.length > 0) {
      const logoFile = data.logo[0];
      const logoFileExtension = logoFile.type.split("image/").pop();
      const logoStorageName = `logo-${uuidv4()}.${logoFileExtension}`;
      try {
        await uploadBytes(
          ref(storage, `contents/${user.id}/${logoStorageName}`),
          logoFile
        );
        updatedProfileData.design.logo = {
          file: logoStorageName,
          name: logoFile.name,
        };
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${logoFile.name} konnte nicht hochgeladen werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // remove bgImg file
    if (
      user &&
      (data.bgImg === null || (data.bgImg && data.bgImg.length > 0)) &&
      user.design?.bgImg
    ) {
      try {
        await deleteObject(
          ref(storage, `contents/${user.id}/${user.design.bgImg.file}`)
        );
        updatedProfileData.design.bgImg = null;
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${user.design.bgImg.name} konnte nicht gelöscht werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // upload bgImg file
    if (user && data.bgImg && data.bgImg.length > 0) {
      const bgImgFile = data.bgImg[0];
      const bgImgFileExtension = bgImgFile.type.split("image/").pop();
      const bgImgStorageName = `bgImg-${uuidv4()}.${bgImgFileExtension}`;
      try {
        await uploadBytes(
          ref(storage, `contents/${user.id}/${bgImgStorageName}`),
          bgImgFile
        );
        updatedProfileData.design.bgImg = {
          file: bgImgStorageName,
          name: bgImgFile.name,
        };
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${bgImgFile.name} konnte nicht hochgeladen werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    try {
      const {
        data: { ok, err, uid },
      } = await saveDataAction(user?.id, updatedProfileData);
      if (ok) {
        await updateUserData();
        toast({
          title: "Das Profil wurde gespeichert.",
          status: "success",
          position: "top",
        });

        // redirect if new customer has been added through admin
        if (!user && uid) {
          navigate(`/customers/${uid}`);
        }
      } else {
        toast({
          title: "Fehler",
          description: err,
          status: "error",
          position: "top",
        });
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      return toast({
        title: "Fehler",
        description: "Das Profil konnte nicht bearbeitet werden.",
        status: "error",
        position: "top",
      });
    }
  };

  return {
    register,
    resetField,
    getValues,
    watch,
    setValue,
    handleSubmit,
    onSubmit,
    setIsLoading,
    isLoading,
    control,
    registrations: {
      email: register("email"),
      password: register("password"),
    },
  };
};
