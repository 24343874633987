import { useSessionStorage } from "../misc/useSessionStorage";
import { useAuthenticationContext } from "./authenticationContext";

export const useSelectedUserId = () => {
  const { loggedInUserId, isAdmin } = useAuthenticationContext();
  const [impersonatedUserId, selectUserId] = useSessionStorage(
    "userId",
    loggedInUserId
  );

  const selectedUserId = isAdmin ? impersonatedUserId : loggedInUserId;

  return {
    selectedUserId: selectedUserId,
    selectUserId,
  };
};
