import { Button, Stack, Text, useToast } from "@chakra-ui/react";
import { sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "reactfire";
import { PageTransition } from "../misc/pageTransition";
import { StyledFormControl } from "../form/styledFormControl";
import { Logo, LogoVariant } from "../misc/logo";
import { StyledTextInput } from "../form/styledTextInput";
import { MainHeading } from "../misc/mainHeading";
import { SubmitHandler, useForm } from "react-hook-form";

type FormValues = {
  email: string;
};

export const ResetPassword = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const { register, handleSubmit } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = async ({ email }) => {
    setIsLoading(true);
    try {
      setHasError(false);
      await sendPasswordResetEmail(auth, email);
      toast({
        title: "Passwort zurücksetzen",
        description: `Code wurde an ${email} versendet.`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      navigate("/login");
    } catch (e: any) {
      setHasError(true);
      toast({
        title: "Passwort zurücksetzen",
        description: e?.message || e,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageTransition>
      <Logo variant={LogoVariant.Small} />
      <MainHeading>Passwort vergessen?</MainHeading>
      <Text>
        Hast du dein Passwort vergessen? Um dein Passwort neu zu setzen, gib
        deinen Benutzernamen oder deine E-Mail-Adresse an. Wir werden dir
        umgehend eine E-Mail mit Informationen zum Wählen eines neuen Passwortes
        senden.
      </Text>
      <br />
      <br />
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <StyledFormControl
            input={
              <StyledTextInput
                type="email"
                placeholder="E-Mail"
                registration={register("email")}
              />
            }
            isInvalid={hasError}
            isDisabled={isLoading}
            isRequired
          />

          <Button
            isLoading={isLoading}
            isActive={!isLoading}
            loadingText="Wird geladen..."
            type="submit"
          >
            Code anfordern
          </Button>
        </Stack>
      </form>
    </PageTransition>
  );
};
