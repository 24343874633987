import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useFirestore } from "reactfire";
import { useAuthenticationContext } from "../admin/authenticationContext";
import { useSelectedUserId } from "../admin/useSelectedUserId";
import { LoadingPlaceholder } from "../misc/loadingPlaceholder";
import { Model } from "./types/model";

export interface UserData {
  id: string;
  abo?: Abonement;
  disabled?: boolean;
  aboId: string;
  address: string;
  name: string;
  plzcity: string;
  phone: string;
  email: string;
  company?: string;
  aboHistory: Abonement[];
  aboChange?: {
    aboId: string;
    ymd: string;
  };
  design?: {
    bgColor: string;
    bgImg?: { name: string; file: string };
    logo?: { name: string; file: string };
    borderColor: string;
    mainColor: string;
    text: string;
    textColor: string;
    url: string;
    font: string;
  };
}

export interface Abonement {
  name: string;
  demo?: boolean;
  monthly?: number;
  yearly?: number;
  models: number;
  ts: Timestamp;
  designEditor: boolean;
  widgets: boolean;
}

export interface Data {
  user: UserData;
  numberOfModelViews?: {
    [key: string]: number;
  };
  models: Model[];
  selectedUserId: string;
  selectUserId: (value: string | undefined) => void;
  updateUserData: () => Promise<void>;
}

const UserDataContext = createContext<Data | undefined>(undefined);

export const UserDataContextProvider: FunctionComponent = ({ children }) => {
  const { selectedUserId, selectUserId } = useSelectedUserId();
  const { isLoggedIn } = useAuthenticationContext();
  const [data, setData] = useState<Data>();
  const firestore = useFirestore();

  useEffect(() => {
    if (!isLoggedIn || !selectedUserId) {
      return;
    }

    const userRef = doc(firestore, "users", selectedUserId);
    const statsDoc = doc(firestore, `stats/models_${selectedUserId}`);
    const modelCollection = collection(firestore, "models");
    const modelQuery = query(
      modelCollection,
      where("userId", "==", selectedUserId)
    );

    const refetch = (): Promise<void> =>
      Promise.all([
        getDoc(userRef),
        getDocs(modelQuery),
        getDoc(statsDoc),
      ]).then(([userSnapshot, modelSnapshot, statsSnapshot]) => {
        const user = {
          ...userSnapshot.data(),
          id: userSnapshot.id,
        } as UserData;
        console.log("User loaded", user);
        setData({
          user,
          selectUserId,
          selectedUserId,
          updateUserData: () => refetch(),
          numberOfModelViews: statsSnapshot.data(),
          models: modelSnapshot.docs.map(
            (doc) => ({ ...doc.data(), id: doc.id } as Model) /// TODO: proper typing
          ),
        });
      });

    refetch();
  }, [firestore, isLoggedIn, selectedUserId]);

  if (!isLoggedIn) {
    return <>{children}</>;
  }

  if (!data) {
    return <LoadingPlaceholder />;
  }

  return (
    <UserDataContext.Provider value={data}>{children}</UserDataContext.Provider>
  );
};

export const useUserDataContext = () => {
  const context = useContext(UserDataContext);

  if (context === undefined) {
    throw new Error("useUserDataContext was used outside of its Provider");
  }

  return context;
};
