import { PageTransition } from "../../misc/pageTransition";
import {
  FormControl,
  FormLabel,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Center,
} from "@chakra-ui/react";
import { useAdminDataContext } from "../../admin/adminDataContext";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { UserData } from "../../data/userDataContext";
import { useForm, useWatch } from "react-hook-form";
import { ArrowDownIcon, ArrowUpIcon } from "@chakra-ui/icons";
import { MainHeading } from "../../misc/mainHeading";
import { SignOutLink } from "../../misc/signOutLink";
import { EditSupport } from "./editSupport";
import { OverflowBox } from "../../misc/overflowBox";
import { OverflowText } from "../../misc/overflowText";

enum SortBy {
  NameAsc,
  NameDesc,
  AboAsc,
  AboDesc,
  ViewsAsc,
  ViewsDesc,
}

interface UserDataWithViews extends UserData {
  views: number;
}

const sortByField = (
  getFieldFn: (user: UserDataWithViews) => string | number,
  direction: "asc" | "desc"
): ((a: UserDataWithViews, b: UserDataWithViews) => number) => {
  return (a, b) => {
    if (getFieldFn(a) > getFieldFn(b)) {
      return direction === "asc" ? 1 : -1;
    }
    if (getFieldFn(a) < getFieldFn(b)) {
      return direction === "asc" ? -1 : 1;
    }
    return 0;
  };
};

const sortFunction = (
  sortBy: SortBy
): ((a: UserDataWithViews, b: UserDataWithViews) => number) => {
  switch (sortBy) {
    default:
    case SortBy.NameAsc:
      return sortByField((u) => u.name, "asc");
    case SortBy.NameDesc:
      return sortByField((u) => u.name, "desc");
    case SortBy.AboAsc:
      return sortByField((u) => u.abo?.name || "", "asc");
    case SortBy.AboDesc:
      return sortByField((u) => u.abo?.name || "", "desc");
    case SortBy.ViewsAsc:
      return sortByField((u) => u.views, "asc");
    case SortBy.ViewsDesc:
      return sortByField((u) => u.views, "desc");
  }
};

export const Customers = () => {
  const { users, numberOfUserViews } = useAdminDataContext();
  const navigate = useNavigate();

  const { register, control } = useForm<{ showArchived: boolean }>();
  const showArchived = useWatch({
    control,
    name: "showArchived",
    defaultValue: false,
  });

  const [sortBy, setSortBy] = useState<SortBy>(SortBy.NameAsc);

  const filteredAndSortedUsers = users
    .map((user) => ({
      ...user,
      views: numberOfUserViews?.[user.id] || 0,
    }))
    .filter((user) => {
      if (!user.abo) {
        return false;
      }
      return showArchived ? true : !user.disabled;
    })
    .sort(sortFunction(sortBy));

  return (
    <PageTransition>
      <MainHeading>Kunden</MainHeading>

      <TableContainer marginBottom={10}>
        <Table variant="simple" size={"sm"}>
          <Thead>
            <Tr>
              <Th
                color={"yellow.500"}
                cursor={"pointer"}
                onClick={() =>
                  sortBy !== SortBy.NameAsc
                    ? setSortBy(SortBy.NameAsc)
                    : setSortBy(SortBy.NameDesc)
                }
              >
                Name {sortBy === SortBy.NameAsc && <ArrowUpIcon />}
                {sortBy === SortBy.NameDesc && <ArrowDownIcon />}
              </Th>
              <Th
                color={"yellow.500"}
                cursor={"pointer"}
                onClick={() =>
                  sortBy !== SortBy.AboAsc
                    ? setSortBy(SortBy.AboAsc)
                    : setSortBy(SortBy.AboDesc)
                }
              >
                Abo {sortBy === SortBy.AboAsc && <ArrowUpIcon />}
                {sortBy === SortBy.AboDesc && <ArrowDownIcon />}
              </Th>
              <Th
                color={"yellow.500"}
                cursor={"pointer"}
                isNumeric
                onClick={() =>
                  sortBy !== SortBy.ViewsAsc
                    ? setSortBy(SortBy.ViewsAsc)
                    : setSortBy(SortBy.ViewsDesc)
                }
              >
                Views {sortBy === SortBy.ViewsAsc && <ArrowUpIcon />}
                {sortBy === SortBy.ViewsDesc && <ArrowDownIcon />}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {filteredAndSortedUsers.map((user) => (
              <Tr
                key={user.id}
                onClick={() => navigate(`/customers/${user.id}`)}
                cursor="pointer"
              >
                <Td>
                  {user.disabled ? (
                    <OverflowText as="s" width="30vw">
                      {user.name} (archiviert)
                    </OverflowText>
                  ) : (
                    <OverflowText width="30vw">
                      {user.name} <small>({user.company})</small>
                    </OverflowText>
                  )}
                </Td>
                <Td>
                  <OverflowBox width="25vw">
                    {user.abo?.name} {user.abo?.demo && "(Demo)"}
                  </OverflowBox>
                </Td>
                <Td isNumeric>{user.views}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <FormControl display="flex" alignItems="center">
        <Switch
          id="show-archived"
          colorScheme={"yellow"}
          marginRight={4}
          {...register("showArchived")}
        />
        <FormLabel htmlFor="show-archived" mb="0">
          Archivierte anzeigen
        </FormLabel>
      </FormControl>

      <br />
      <Center>
        <Button
          onClick={() => navigate(`/customers/0`)}
          variant={"outline"}
          color="yellow.500"
          borderColor="yellow.500"
          _hover={{ bg: "yellow.500", color: "black" }}
          _active={{ background: "transparent" }}
        >
          Hinzufügen
        </Button>
      </Center>
      <br />
      <br />

      <EditSupport />
      <br />
      <br />

      <SignOutLink />
      <br />
      <br />
    </PageTransition>
  );
};
