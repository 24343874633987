import { Box, Container } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";

const MotionBox = motion(Box);

export const PageTransition: FunctionComponent = ({ children }) => {
  const location = useLocation();
  const locationState = location.state as
    | {
        current: number | undefined;
        position: number | undefined;
      }
    | undefined;

  let exit;
  let enter;
  if (!locationState?.current || !locationState?.position) {
    enter = {
      x: 0,
      opacity: 0,
    };
    exit = { x: 0, opacity: 0 };
  } else if (locationState.current < locationState.position) {
    enter = {
      x: "80vw",
      opacity: 0,
    };
    exit = { x: "-80vw", opacity: 0 };
  } else {
    enter = {
      x: "-80vw",
      opacity: 0,
    };
    exit = { x: "80vw", opacity: 0 };
  }

  return (
    <MotionBox
      initial={enter}
      animate={{
        zIndex: 1,
        opacity: 1,
        x: 0,
        transitionEnd: {
          overflowY: "auto",
        },
      }}
      exit={exit}
      onAnimationComplete={() => window.scroll({ top: 0 })}
      style={{
        overflow: "hidden",
        paddingBottom: "120px",
        position: "fixed",
        height: "100%",
        width: "100%",
        top: 0,
        left: 0,
      }}
      transition={{ duration: 0.4, ease: "easeInOut", height: "auto" }}
    >
      <Container
        padding={["3rem 1.5rem", "3rem 1.5rem", "3rem"]}
        maxWidth={["100%", "100%", "100%", "960px", "1152px"]}
      >
        {children}
      </Container>
    </MotionBox>
  );
};
