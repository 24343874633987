import {
  Button,
  Stack,
  Text,
  Checkbox,
  Link,
  Box,
  Center,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageTransition } from "../misc/pageTransition";
import { StyledFormControl } from "../form/styledFormControl";
import { Logo, LogoVariant } from "../misc/logo";
import { StyledTextInput } from "../form/styledTextInput";
import { StyledPasswordInput } from "../form/styledPasswordInput";
import { SubHeading } from "../misc/subHeading";
import { MainHeading } from "../misc/mainHeading";
import { SubmitHandler, useForm } from "react-hook-form";
import { StyledSelectInput } from "../form/styledSelectInput";
import { useAbos } from "../data/useAbos";
import { useSignUp } from "../data/functions";

type FormValues = {
  company: string;
  name: string;
  address: string;
  plzcity: string;
  phone: string;
  email: string;
  password: string;
  aboId: string;
  tos: boolean;
};

export const Register = () => {
  const { register, handleSubmit } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { abos } = useAbos();
  const navigate = useNavigate();
  const toast = useToast();
  const signUp = useSignUp();

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true);
    try {
      await signUp(data);
      navigate("/");
    } catch (e) {
      toast({
        title: "Registrierung",
        description: "Das 3D-Profil konnte nicht erstellt werden.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageTransition>
      <Logo variant={LogoVariant.Small} />
      <MainHeading>3D-Profil erstellen</MainHeading>
      <Text>
        Du möchtest von den Möglichkeiten des 3D-Tools profitieren? Das freut
        uns natürlich. Erstelle nachfolgend dein 3D-Profil. Anschliessend kannst
        du zwischen unterschiedlichen Abonomenten wählen und deine Modelle
        hochladen.
      </Text>
      <br />

      <form onSubmit={handleSubmit(onSubmit)}>
        <SubHeading>Deine Angaben</SubHeading>
        <Stack spacing={3}>
          <StyledFormControl
            input={
              <StyledTextInput
                type="text"
                placeholder="Firmenname"
                registration={register("company")}
              />
            }
            isDisabled={isLoading}
            isRequired
          />
          <StyledFormControl
            input={
              <StyledTextInput
                type="text"
                placeholder="Vor- und Nachname"
                registration={register("name")}
              />
            }
            isDisabled={isLoading}
            isRequired
          />
          <StyledFormControl
            input={
              <StyledTextInput
                type="text"
                placeholder="Strasse, Nr."
                registration={register("address")}
              />
            }
            isDisabled={isLoading}
            isRequired
          />
          <StyledFormControl
            input={
              <StyledTextInput
                type="text"
                placeholder="PLZ Ort"
                registration={register("plzcity")}
              />
            }
            isDisabled={isLoading}
            isRequired
          />
          <StyledFormControl
            input={
              <StyledTextInput
                type="text"
                placeholder="Telefonnummer"
                registration={register("phone")}
              />
            }
            isDisabled={isLoading}
            isRequired
          />
        </Stack>
        <br />

        <SubHeading>Dein Login</SubHeading>
        <Stack spacing={3}>
          <StyledFormControl
            input={
              <StyledTextInput
                type="email"
                placeholder="E-Mail Adresse"
                registration={register("email")}
              />
            }
            isDisabled={isLoading}
            isRequired
          />
          <StyledFormControl
            input={
              <StyledPasswordInput
                placeholder="Passwort"
                registration={register("password")}
              />
            }
            isDisabled={isLoading}
            isRequired
          />
        </Stack>
        <br />

        <SubHeading>Dein Abo</SubHeading>
        <Stack spacing={3}>
          <StyledFormControl
            input={
              <StyledSelectInput
                placeholder="Wähle dein Abo"
                registration={register("aboId")}
              >
                {abos.map((abo) => (
                  <option key={abo.id} value={abo.id}>
                    {abo.name}
                  </option>
                ))}
              </StyledSelectInput>
            }
            isDisabled={isLoading}
            isRequired
          />
          <Box fontSize={"sm"} textAlign={"right"}>
            <Link href="https://3d-tool.ch/#ABOS" target={"_blank"} isExternal>
              zu den Abos und Preisen
            </Link>
          </Box>
        </Stack>

        <br />
        <br />

        <StyledFormControl
          input={
            <Checkbox
              colorScheme="yellow"
              borderColor="yellow.500"
              {...register("tos")}
            >
              Ich habe die{" "}
              <Link href="https://3d-tool.ch/impressum/" isExternal>
                AGB's
              </Link>{" "}
              gelesen und bin damit einverstanden.
            </Checkbox>
          }
          isDisabled={isLoading}
          isRequired
        />

        <br />
        <br />

        <Center>
          <Button
            isLoading={isLoading}
            isActive={!isLoading}
            loadingText="Wird erstellt..."
            type="submit"
          >
            3D-Profil erstellen
          </Button>
        </Center>
      </form>
    </PageTransition>
  );
};
