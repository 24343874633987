import { UserData } from "../data/userDataContext";
import { de } from "date-fns/locale";
import { format, addMonths, differenceInMonths } from "date-fns";

export const formatSubscriptionDuration = (user: UserData): string => {
  if (!user.abo?.ts) {
    return "-";
  }

  const startDate = user.abo.ts.toDate();
  const monthDiff = differenceInMonths(startDate, new Date());

  let endDate: Date;
  if (user.abo.monthly) {
    endDate = addMonths(startDate, Math.max(3, monthDiff + 1));
  } else {
    endDate = addMonths(startDate, Math.max(12, monthDiff + 1));
  }

  return format(endDate, "dd. MMMM yyyy", { locale: de });
};
