import logo from "../assets/img/logo.svg";
import icon from "../assets/img/icon.svg";
import { Container, AspectRatio, Image, Link } from "@chakra-ui/react";
import { FunctionComponent } from "react";

interface LogoProps {
  variant: LogoVariant;
}

export enum LogoVariant {
  Big,
  Small,
}

export const Logo: FunctionComponent<LogoProps> = ({ variant }) => {
  if (variant === LogoVariant.Small) {
    return (
      <AspectRatio ratio={71 / 75} maxWidth={"80px"} margin="48px auto">
        <Image
          src={icon}
          alt="3D-Tool Icon"
          userSelect="none"
          pointerEvents="none"
        />
      </AspectRatio>
    );
  }

  return (
    <Container width="75%">
      <AspectRatio ratio={100 / 31} margin="10vh 0px">
        <Link href="https://3d-tool.ch/">
          <Image
            src={logo}
            alt="3D-Tool Logo"
            userSelect="none"
            pointerEvents="none"
          />
        </Link>
      </AspectRatio>
    </Container>
  );
};
