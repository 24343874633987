import { Select, Stack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { Control, UseFormRegisterReturn, useWatch } from "react-hook-form";
import { useAbos } from "../../data/useAbos";
import { StyledFormControl } from "../../form/styledFormControl";
import { StyledNumberInput } from "../../form/styledNumberInput";
import { SubHeading } from "../../misc/subHeading";
import { ProfileFormValues } from "./profileFormValues";

interface CustomerDetailAboProps {
  registration: UseFormRegisterReturn;
  numberOfModelsRegistration: UseFormRegisterReturn;
  control: Control<ProfileFormValues>;
  isLoading: boolean;
}

export const CustomerDetailAbo: FunctionComponent<CustomerDetailAboProps> = ({
  control,
  registration,
  numberOfModelsRegistration,
  isLoading,
}) => {
  const { abos } = useAbos();
  const aboId = useWatch({
    name: "aboId",
    control,
  });

  return (
    <>
      <SubHeading>Abo</SubHeading>
      <Select
        disabled={isLoading}
        colorScheme="whiteAlpha"
        errorBorderColor="red.400"
        focusBorderColor="yellow.500"
        {...registration}
      >
        {abos.map((abo) => (
          <option key={abo.id} value={abo.id}>
            {abo.name}
          </option>
        ))}
        <option key={"CUSTOM"} value={"CUSTOM"}>
          CUSTOM
        </option>
      </Select>
      {aboId === "CUSTOM" && (
        <Stack spacing={3} paddingTop={3}>
          <StyledFormControl
            input={
              <StyledNumberInput
                registration={numberOfModelsRegistration}
                placeholder={"Anzahl Modelle"}
              />
            }
            isDisabled={isLoading}
          />
        </Stack>
      )}
    </>
  );
};
