import { extendTheme } from "@chakra-ui/react";
import type { ComponentStyleConfig } from "@chakra-ui/theme";

const Input: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: "whiteAlpha",
    errorBorderColor: "red.400",
    focusBorderColor: "yellow.500",
  },
};

const Select: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: "whiteAlpha",
    errorBorderColor: "red.400",
    focusBorderColor: "yellow.500",
  },
};

const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: 300,
    textTransform: "uppercase",
    fontFamily: "Montserrat, sans-serif",
  },
};

const Button: ComponentStyleConfig = {
  defaultProps: {
    size: "md",
    variant: "solid",
  },
  baseStyle: {
    fontWeight: 500,
    textTransform: "uppercase",
  },
  variants: {
    solid: {
      paddingLeft: 10,
      paddingRight: 10,
      bg: "whiteAlpha.900",
      color: "black",
    },
  },
};

// Based on https://chakra-ui.com/docs/styled-system/theming/theme
// https://chakra-ui.com/docs/styled-system/theming/customize-theme
export const theme = extendTheme({
  components: {
    Input,
    Button,
    Heading,
    Select,
    Link: {
      baseStyle: {
        color: "yellow.500",
        textDecoration: "underline",
        _hover: {
          color: "white",
        },
      },
    },
  },
  shadows: {
    outline: "none", // "0 0 0 2px rgba(236, 201, 75, 0.6);",
  },
  styles: {
    global: {
      // needed for sticky footer
      "html,body,#root": { height: "100%" },

      // disable blue click-area surrounding links on tap
      a: {
        "-webkit-tap-highlight-color": "transparent",
        "-webkit-touch-callout": "none",
        "-webkit-user-select": "none",
        "-khtml-user-select": "none",
        "-moz-user-select": "none",
        "-ms-user-select": "none",
        "user-select": "none",
      },
      "a:focus": {
        outline: "none !important",
      },

      body: {
        color: "white",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: 400,
      },
    },
  },
});
