import { LayoutProps, Select, ThemingProps } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface StyledSelectInputProps extends ThemingProps<"Select">, LayoutProps {
  registration?: UseFormRegisterReturn;
  onChangeHandler?: (value: string) => void;
  value?: string;
  placeholder?: string;
}

export const StyledSelectInput: FunctionComponent<StyledSelectInputProps> = ({
  placeholder,
  registration,
  children,
  size,
  value,
  onChangeHandler,
  ...props
}) => {
  return (
    <Select
      placeholder={placeholder}
      colorScheme="whiteAlpha"
      errorBorderColor="red.400"
      focusBorderColor="yellow.500"
      borderColor="whiteAlpha.900"
      _hover={{
        borderColor: "whiteAlpha.900",
      }}
      size={size}
      value={value}
      {...(onChangeHandler
        ? {
            onChange: (e) => onChangeHandler(e.currentTarget.value),
          }
        : registration)}
      {...props}
    >
      {children}
    </Select>
  );
};
