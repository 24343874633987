import icon from "../assets/img/register.svg";
import { AspectRatio, Image, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FunctionComponent } from "react";

export const LinkRegister: FunctionComponent = () => {
  return (
    <Link to="/register">
      <AspectRatio width={50} ratio={139 / 150} margin="auto">
        <Image
          src={icon}
          alt="Register"
          userSelect="none"
          pointerEvents="none"
        />
      </AspectRatio>
      <Text fontSize={"small"}>3D-Profil erstellen</Text>
    </Link>
  );
};
