import { Stack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { UseFormRegister } from "react-hook-form";
import { StyledFormControl } from "../../form/styledFormControl";
import { StyledTextInput } from "../../form/styledTextInput";

export type ProfileAddressFormValues = {
  company?: string;
  name?: string;
  address?: string;
  plzcity?: string;
  phone?: string;
};

interface ProfileAddressFormFormProps {
  register: UseFormRegister<ProfileAddressFormValues>;
  isLoading: boolean;
}

export const ProfileAddressForm: FunctionComponent<
  ProfileAddressFormFormProps
> = ({ register, isLoading }) => {
  return (
    <Stack spacing={3} paddingTop={4} paddingBottom={4}>
      <StyledFormControl
        input={
          <StyledTextInput
            type="text"
            registration={register("company")}
            placeholder={"Firma"}
          />
        }
        isDisabled={isLoading}
      />

      <StyledFormControl
        input={
          <StyledTextInput
            type="text"
            registration={register("name")}
            placeholder={"Vor- & Nachname"}
          />
        }
        isDisabled={isLoading}
      />

      <StyledFormControl
        input={
          <StyledTextInput
            type="text"
            registration={register("address")}
            placeholder={"Strasse, Nr."}
          />
        }
        isDisabled={isLoading}
      />

      <StyledFormControl
        input={
          <StyledTextInput
            type="text"
            registration={register("plzcity")}
            placeholder={"PLZ Ort"}
          />
        }
        isDisabled={isLoading}
      />

      <StyledFormControl
        input={
          <StyledTextInput
            type="text"
            registration={register("phone")}
            placeholder={"Telefonnummer"}
          />
        }
        isDisabled={isLoading}
      />
    </Stack>
  );
};
