import { Box, HStack, Spacer } from "@chakra-ui/react";
import { FunctionComponent, memo } from "react";
import { useLocation } from "react-router-dom";
import { AdminBar } from "../admin/adminBar";
import { AdminContent } from "../admin/adminContent";
import { useAuthenticationContext } from "../admin/authenticationContext";
import { LinkCosts } from "./linkCosts";
import { LinkHome } from "./linkHome";
import { LinkModels } from "./linkModels";
import { LinkProfile } from "./linkProfile";
import { LinkRegister } from "./linkRegister";

const StickyBox: FunctionComponent = memo(({ children }) => {
  return (
    <>
      <Box
        position={"fixed"}
        bottom={0}
        left={0}
        width={"100%"}
        height={"200px"}
        zIndex={"sticky"}
        pointerEvents={"none"}
        background={"linear-gradient(180deg,transparent,#000 60px)"}
      ></Box>
      <Box
        position={"fixed"}
        bottom={0}
        left={0}
        width={"100%"}
        zIndex={"sticky"}
      >
        {children}
      </Box>
    </>
  );
});

const CenteredBox: FunctionComponent = memo(({ children }) => {
  return (
    <Box margin={"auto"} maxWidth={["100%", "100%", "100%", "960px", "1152px"]}>
      {children}
    </Box>
  );
});

export const Navigation: FunctionComponent = () => {
  const { isLoggedIn } = useAuthenticationContext();
  const location = useLocation();

  const isAdminUrl =
    ["/"].some((prefix) => prefix.includes(location.pathname)) ||
    ["/models", "/costs", "/profile", "/login", "/customers"].some((prefix) =>
      location.pathname.startsWith(prefix)
    );

  if (!isAdminUrl) {
    return null;
  }

  const { position } =
    (location.state as {
      position: number | undefined;
    }) || {};

  if (!isLoggedIn) {
    if (location.pathname === "/login") {
      return (
        <StickyBox>
          <CenteredBox>
            <HStack paddingBottom={5}>
              <Spacer />
              <LinkRegister />
              <Spacer />
            </HStack>
          </CenteredBox>
        </StickyBox>
      );
    }

    return null;
  }

  return (
    <StickyBox>
      <CenteredBox>
        <HStack paddingBottom={5}>
          <Spacer />
          <LinkHome currentPosition={position || null} position={1} />
          <Spacer />
          <LinkModels currentPosition={position || null} position={2} />
          <Spacer />
          <LinkCosts currentPosition={position || null} position={3} />
          <Spacer />
          <LinkProfile currentPosition={position || null} position={4} />
          <Spacer />
        </HStack>
      </CenteredBox>
      <AdminContent>
        <AdminBar />
      </AdminContent>
    </StickyBox>
  );
};
