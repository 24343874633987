import { FunctionComponent } from "react";
import { AdminDataContextProvider } from "./adminDataContext";
import { useAuthenticationContext } from "./authenticationContext";

export const AdminContextProviders: FunctionComponent = ({ children }) => {
  const { isAdmin } = useAuthenticationContext();

  return isAdmin ? (
    <AdminDataContextProvider>{children}</AdminDataContextProvider>
  ) : (
    <>{children}</>
  );
};
