// Testing URLs
// https://app.3d-tool.ch/v/hTbMUfwKc7orPY6x4Pjz with background image
// https://app.3d-tool.ch/v/SE5uLA0iTmxzjrq3MlUU with background color & more text
// https://app.3d-tool.ch/v/tbSR13lJ3nE36vdSf41S without link
// https://app.3d-tool.ch/v/gMzTlBDycuInbfLV9i2P archived

import {
  Box,
  Center,
  Container,
  Icon,
  IconButton,
  Text,
  Link,
} from "@chakra-ui/react";
import { MdQrCode2 } from "react-icons/md";
import { HiOutlineCubeTransparent } from "react-icons/hi";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { doc, DocumentReference } from "firebase/firestore";
import { LoadingPlaceholder } from "../misc/loadingPlaceholder";
import { ModelViewer } from "../ar/modelViewer";
import { Model } from "../data/types/model";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import { nl2br } from "../misc/nl2br";
import { useTrackView } from "../data/functions";
import { useAuthenticationContext } from "../admin/authenticationContext";

const BASE_URL =
  "https://storage.googleapis.com/app-3d-tool.appspot.com/contents";
const SHARE_BASE_URL = "https://app.3d-tool.ch";

enum DisplayMode {
  Model,
  Info,
  QrCode,
}

export const ArModel = () => {
  let { id } = useParams();
  const firestore = useFirestore();
  const trackView = useTrackView();
  const { isLoggedIn } = useAuthenticationContext();
  const [displayMode, setDisplayMode] = useState<DisplayMode>(
    DisplayMode.Model
  );

  // SUPPORT CASE 02.11.2022: user has deleted model but model URL has been printed on a flyer and we need to show correct model
  if (id === "RiVype9muJfOo1WuZmUe") {
    id = "OQO7Ei9dL5RvuOsPBSFH";
  }

  const ref = doc(firestore, `models/${id}`) as DocumentReference<
    Model | undefined
  >;
  const { status, data } = useFirestoreDocDataOnce(ref, {
    idField: "id",
  });

  const modelId = data?.id;

  useEffect(() => {
    // track impressions if the modelId is valid and the user is not logged in
    if (modelId && !isLoggedIn) {
      trackView(modelId, "app");
    }
  }, [modelId, isLoggedIn, trackView]);

  if (status === "loading") {
    return <LoadingPlaceholder />;
  }

  if (data?.disabled) {
    return (
      <Center height={"100%"}>
        <Text as="s" size={"xl"} color="red.500">
          Dieses Modell ist nicht mehr verfügbar
        </Text>
      </Center>
    );
  }

  if (!data) {
    return (
      <Center height={"100%"}>
        <Text size={"xl"}>Das Modell wurde nicht gefunden :(</Text>
      </Center>
    );
  }

  const backgroundImage = data.design?.bgImg?.file
    ? `${BASE_URL}/${data.userId}/${data.design.bgImg.file}`
    : undefined;

  return (
    <Box
      bgColor={data.design?.bgColor}
      backgroundSize={"cover"}
      bgImage={backgroundImage}
      height={"100%"}
    >
      <Container
        height={"90%"}
        overflow={"hidden"}
        position={"relative"}
        bg={data.design?.mainColor}
        borderStyle={"solid"}
        borderWidth={"0 5px 5px 5px"}
        borderRadius={"0 0 50px 50px"}
        borderColor={data.design?.borderColor}
        padding={"1.5rem"}
        // hack to fix border radius cropping on safari
        // https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
        transform={"translateZ(0)"}
        maxWidth={["80%", "80%", "80%", "80%", "80%", "1344px"]}
      >
        {data.design?.logo && (
          <img
            src={`${BASE_URL}/${data.userId}/${data.design.logo.file}`}
            alt={data.design.logo.name}
            style={{ maxHeight: "10vh", margin: "0 auto 1.5rem auto" }}
          />
        )}

        <Box
          position={"absolute"}
          left={0}
          right={0}
          top={0}
          bottom={0}
          zIndex={"base"}
          pointerEvents={displayMode === DisplayMode.Model ? "all" : "none"}
          opacity={displayMode === DisplayMode.Model ? 1 : 0}
        >
          <ModelViewer
            name={data.name}
            placement={data.placement}
            posterColor={data.design?.mainColor || "transparent"}
            usdzSrc={`${BASE_URL}/${data.userId}/${data.usdz?.file}`}
            glbSrc={`${BASE_URL}/${data.userId}/${data.glb?.file}`}
            arbutton={
              <>
                <Icon
                  as={HiOutlineCubeTransparent}
                  className="stroke-custom"
                  color={data.design?.borderColor || "yellow.500"}
                  width={"100%"}
                  height={"100%"}
                />
                <style>{`.stroke-custom > path {
              stroke-width: 1.2px !important;
            }`}</style>
              </>
            }
          />
        </Box>

        {displayMode === DisplayMode.Info && (
          <Box color={data.design?.textColor} margin={"auto"} maxWidth={"80%"}>
            {data.text && <Text paddingTop={10}>{nl2br(data.text)}</Text>}
            {data.design?.text && (
              <Text paddingTop={10}>{nl2br(data.design.text)}</Text>
            )}
          </Box>
        )}

        {displayMode === DisplayMode.QrCode && (
          <Center paddingTop={50}>
            <Box background={"white"} padding={5}>
              <QRCodeCanvas
                value={`${SHARE_BASE_URL}/v/${data.id}`}
                size={180}
              />
            </Box>
          </Center>
        )}

        <Center position={"absolute"} zIndex={"modal"} left={6} bottom={6}>
          <IconButton
            size={"lg"}
            variant={"unstyled"}
            color={data.design?.borderColor || "yellow.500"}
            aria-label={"Infos anzeigen"}
            icon={<InfoOutlineIcon />}
            onClick={() =>
              setDisplayMode(
                displayMode === DisplayMode.Info
                  ? DisplayMode.Model
                  : DisplayMode.Info
              )
            }
          />
        </Center>

        {displayMode !== DisplayMode.Model && (
          <Center
            position={"absolute"}
            zIndex={"base"}
            left={6}
            right={6}
            bottom={6}
          >
            <IconButton
              size={"lg"}
              variant={"unstyled"}
              color={data.design?.borderColor || "yellow.500"}
              aria-label={"3D-Modell anzeigen"}
              icon={<Icon as={HiOutlineCubeTransparent} marginTop={3} />}
              onClick={() => setDisplayMode(DisplayMode.Model)}
            />
          </Center>
        )}

        <Center position={"absolute"} zIndex={"modal"} right={6} bottom={6}>
          <IconButton
            size={"lg"}
            variant={"unstyled"}
            color={data.design?.borderColor || "yellow.500"}
            aria-label={"QR-Code anzeigen"}
            icon={<Icon as={MdQrCode2} marginTop={3} />}
            onClick={() =>
              setDisplayMode(
                displayMode === DisplayMode.QrCode
                  ? DisplayMode.Model
                  : DisplayMode.QrCode
              )
            }
          />
        </Center>
      </Container>

      <Center
        position={"absolute"}
        left={2}
        right={2}
        bottom={2}
        fontSize={"sm"}
        color={"whiteAlpha.800"}
      >
        {data.design?.url ? (
          <>
            <Link
              href={
                data.design.url.match(/^https?:\/\//)
                  ? data.design.url
                  : `https://${data.design.url}`
              }
              target="_blank"
              isExternal
              color={data.design.borderColor || "yellow.500"}
              paddingStart={1}
              paddingEnd={2}
            >
              {data.design.url.replace(/https?:\/\//, "")}
            </Link>
          </>
        ) : (
          <>
            Powered by ©
            <Link
              href="https://3d-tool.ch"
              target="_blank"
              isExternal
              paddingStart={1}
              paddingEnd={2}
            >
              3D-TOOL.CH
            </Link>
            | 2021
          </>
        )}
      </Center>
    </Box>
  );
};
