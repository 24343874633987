export const googleFonts = [
  {
    key: "abel",
    name: "Abel",
    href: "https://fonts.googleapis.com/css?family=Abel:regular&text=Abel",
  },
  {
    key: "abril-fatface",
    name: "Abril Fatface",
    href: "https://fonts.googleapis.com/css?family=Abril+Fatface:regular&text=AbrilFatfce",
  },
  {
    key: "anton",
    name: "Anton",
    href: "https://fonts.googleapis.com/css?family=Anton:regular&text=Anto",
  },
  {
    key: "archivo",
    name: "Archivo",
    href: "https://fonts.googleapis.com/css?family=Archivo:regular&text=Archivo",
  },
  {
    key: "arimo",
    name: "Arimo",
    href: "https://fonts.googleapis.com/css?family=Arimo:regular&text=Arimo",
  },
  {
    key: "arvo",
    name: "Arvo",
    href: "https://fonts.googleapis.com/css?family=Arvo:regular&text=Arvo",
  },
  {
    key: "asap",
    name: "Asap",
    href: "https://fonts.googleapis.com/css?family=Asap:regular&text=Asap",
  },
  {
    key: "assistant",
    name: "Assistant",
    href: "https://fonts.googleapis.com/css?family=Assistant:regular&text=Asitan",
  },
  {
    key: "barlow",
    name: "Barlow",
    href: "https://fonts.googleapis.com/css?family=Barlow:regular&text=Barlow",
  },
  {
    key: "barlow-condensed",
    name: "Barlow Condensed",
    href: "https://fonts.googleapis.com/css?family=Barlow+Condensed:regular&text=BarlowCndes",
  },
  {
    key: "bebas-neue",
    name: "Bebas Neue",
    href: "https://fonts.googleapis.com/css?family=Bebas+Neue:regular&text=BebasNu",
  },
  {
    key: "bitter",
    name: "Bitter",
    href: "https://fonts.googleapis.com/css?family=Bitter:regular&text=Biter",
  },
  {
    key: "cabin",
    name: "Cabin",
    href: "https://fonts.googleapis.com/css?family=Cabin:regular&text=Cabin",
  },
  {
    key: "cairo",
    name: "Cairo",
    href: "https://fonts.googleapis.com/css?family=Cairo:regular&text=Cairo",
  },
  {
    key: "catamaran",
    name: "Catamaran",
    href: "https://fonts.googleapis.com/css?family=Catamaran:regular&text=Catmrn",
  },
  {
    key: "caveat",
    name: "Caveat",
    href: "https://fonts.googleapis.com/css?family=Caveat:regular&text=Cavet",
  },
  {
    key: "comfortaa",
    name: "Comfortaa",
    href: "https://fonts.googleapis.com/css?family=Comfortaa:regular&text=Comfrta",
  },
  {
    key: "cormorant-garamond",
    name: "Cormorant Garamond",
    href: "https://fonts.googleapis.com/css?family=Cormorant+Garamond:regular&text=CormantGd",
  },
  {
    key: "dancing-script",
    name: "Dancing Script",
    href: "https://fonts.googleapis.com/css?family=Dancing+Script:regular&text=DancigSrpt",
  },
  {
    key: "dm-sans",
    name: "DM Sans",
    href: "https://fonts.googleapis.com/css?family=DM+Sans:regular&text=DMSans",
  },
  {
    key: "dosis",
    name: "Dosis",
    href: "https://fonts.googleapis.com/css?family=Dosis:regular&text=Dosi",
  },
  {
    key: "eb-garamond",
    name: "EB Garamond",
    href: "https://fonts.googleapis.com/css?family=EB+Garamond:regular&text=EBGarmond",
  },
  {
    key: "exo-2",
    name: "Exo 2",
    href: "https://fonts.googleapis.com/css?family=Exo+2:regular&text=Exo2",
  },
  {
    key: "fira-sans",
    name: "Fira Sans",
    href: "https://fonts.googleapis.com/css?family=Fira+Sans:regular&text=FiraSns",
  },
  {
    key: "fira-sans-condensed",
    name: "Fira Sans Condensed",
    href: "https://fonts.googleapis.com/css?family=Fira+Sans+Condensed:regular&text=FiraSnsCode",
  },
  {
    key: "fjalla-one",
    name: "Fjalla One",
    href: "https://fonts.googleapis.com/css?family=Fjalla+One:regular&text=FjalOne",
  },
  {
    key: "heebo",
    name: "Heebo",
    href: "https://fonts.googleapis.com/css?family=Heebo:regular&text=Hebo",
  },
  {
    key: "hind",
    name: "Hind",
    href: "https://fonts.googleapis.com/css?family=Hind:regular&text=Hind",
  },
  {
    key: "hind-madurai",
    name: "Hind Madurai",
    href: "https://fonts.googleapis.com/css?family=Hind+Madurai:regular&text=HindMaur",
  },
  {
    key: "hind-siliguri",
    name: "Hind Siliguri",
    href: "https://fonts.googleapis.com/css?family=Hind+Siliguri:regular&text=HindSlgur",
  },
  {
    key: "ibm-plex-sans",
    name: "IBM Plex Sans",
    href: "https://fonts.googleapis.com/css?family=IBM+Plex+Sans:regular&text=IBMPlexSans",
  },
  {
    key: "ibm-plex-serif",
    name: "IBM Plex Serif",
    href: "https://fonts.googleapis.com/css?family=IBM+Plex+Serif:regular&text=IBMPlexSrif",
  },
  {
    key: "inconsolata",
    name: "Inconsolata",
    href: "https://fonts.googleapis.com/css?family=Inconsolata:regular&text=Incoslat",
  },
  {
    key: "indie-flower",
    name: "Indie Flower",
    href: "https://fonts.googleapis.com/css?family=Indie+Flower:regular&text=IndieFlowr",
  },
  {
    key: "inter",
    name: "Inter",
    href: "https://fonts.googleapis.com/css?family=Inter:regular&text=Inter",
  },
  {
    key: "josefin-sans",
    name: "Josefin Sans",
    href: "https://fonts.googleapis.com/css?family=Josefin+Sans:regular&text=JosefinSa",
  },
  {
    key: "kanit",
    name: "Kanit",
    href: "https://fonts.googleapis.com/css?family=Kanit:regular&text=Kanit",
  },
  {
    key: "karla",
    name: "Karla",
    href: "https://fonts.googleapis.com/css?family=Karla:regular&text=Karl",
  },
  {
    key: "lato",
    name: "Lato",
    href: "https://fonts.googleapis.com/css?family=Lato:regular&text=Lato",
  },
  {
    key: "libre-baskerville",
    name: "Libre Baskerville",
    href: "https://fonts.googleapis.com/css?family=Libre+Baskerville:regular&text=LibreBaskvl",
  },
  {
    key: "libre-franklin",
    name: "Libre Franklin",
    href: "https://fonts.googleapis.com/css?family=Libre+Franklin:regular&text=LibreFankl",
  },
  {
    key: "lobster",
    name: "Lobster",
    href: "https://fonts.googleapis.com/css?family=Lobster:regular&text=Lobster",
  },
  {
    key: "lora",
    name: "Lora",
    href: "https://fonts.googleapis.com/css?family=Lora:regular&text=Lora",
  },
  {
    key: "m-plus-rounded-1c",
    name: "M PLUS Rounded 1c",
    href: "https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c:regular&text=MPLUSRounde1c",
  },
  {
    key: "manrope",
    name: "Manrope",
    href: "https://fonts.googleapis.com/css?family=Manrope:regular&text=Manrope",
  },
  {
    key: "maven-pro",
    name: "Maven Pro",
    href: "https://fonts.googleapis.com/css?family=Maven+Pro:regular&text=MavenPro",
  },
  {
    key: "merriweather",
    name: "Merriweather",
    href: "https://fonts.googleapis.com/css?family=Merriweather:regular&text=Meriwath",
  },
  {
    key: "merriweather-sans",
    name: "Merriweather Sans",
    href: "https://fonts.googleapis.com/css?family=Merriweather+Sans:regular&text=MeriwathSns",
  },
  {
    key: "montserrat",
    name: "Montserrat",
    href: "https://fonts.googleapis.com/css?family=Montserrat:regular&text=Montsera",
  },
  {
    key: "mukta",
    name: "Mukta",
    href: "https://fonts.googleapis.com/css?family=Mukta:regular&text=Mukta",
  },
  {
    key: "mulish",
    name: "Mulish",
    href: "https://fonts.googleapis.com/css?family=Mulish:regular&text=Mulish",
  },
  {
    key: "nanum-gothic",
    name: "Nanum Gothic",
    href: "https://fonts.googleapis.com/css?family=Nanum+Gothic:regular&text=NanumGothic",
  },
  {
    key: "noto-sans",
    name: "Noto Sans",
    href: "https://fonts.googleapis.com/css?family=Noto+Sans:regular&text=NotSans",
  },
  {
    key: "noto-sans-hk",
    name: "Noto Sans HK",
    href: "https://fonts.googleapis.com/css?family=Noto+Sans+HK:regular&text=NotSansHK",
  },
  {
    key: "noto-sans-jp",
    name: "Noto Sans JP",
    href: "https://fonts.googleapis.com/css?family=Noto+Sans+JP:regular&text=NotSansJP",
  },
  {
    key: "noto-sans-kr",
    name: "Noto Sans KR",
    href: "https://fonts.googleapis.com/css?family=Noto+Sans+KR:regular&text=NotSansKR",
  },
  {
    key: "noto-sans-sc",
    name: "Noto Sans SC",
    href: "https://fonts.googleapis.com/css?family=Noto+Sans+SC:regular&text=NotSansC",
  },
  {
    key: "noto-sans-tc",
    name: "Noto Sans TC",
    href: "https://fonts.googleapis.com/css?family=Noto+Sans+TC:regular&text=NotSansTC",
  },
  {
    key: "noto-serif",
    name: "Noto Serif",
    href: "https://fonts.googleapis.com/css?family=Noto+Serif:regular&text=NotSerif",
  },
  {
    key: "noto-serif-jp",
    name: "Noto Serif JP",
    href: "https://fonts.googleapis.com/css?family=Noto+Serif+JP:regular&text=NotSerifJP",
  },
  {
    key: "nunito",
    name: "Nunito",
    href: "https://fonts.googleapis.com/css?family=Nunito:regular&text=Nunito",
  },
  {
    key: "nunito-sans",
    name: "Nunito Sans",
    href: "https://fonts.googleapis.com/css?family=Nunito+Sans:regular&text=NunitoSas",
  },
  {
    key: "open-sans",
    name: "Open Sans",
    href: "https://fonts.googleapis.com/css?family=Open+Sans:regular",
  },
  {
    key: "oswald",
    name: "Oswald",
    href: "https://fonts.googleapis.com/css?family=Oswald:regular&text=Oswald",
  },
  {
    key: "overpass",
    name: "Overpass",
    href: "https://fonts.googleapis.com/css?family=Overpass:regular&text=Overpas",
  },
  {
    key: "oxygen",
    name: "Oxygen",
    href: "https://fonts.googleapis.com/css?family=Oxygen:regular&text=Oxygen",
  },
  {
    key: "pacifico",
    name: "Pacifico",
    href: "https://fonts.googleapis.com/css?family=Pacifico:regular&text=Pacifo",
  },
  {
    key: "padauk",
    name: "Padauk",
    href: "https://fonts.googleapis.com/css?family=Padauk:regular&text=Paduk",
  },
  {
    key: "patrick-hand",
    name: "Patrick Hand",
    href: "https://fonts.googleapis.com/css?family=Patrick+Hand:regular&text=PatrickHnd",
  },
  {
    key: "permanent-marker",
    name: "Permanent Marker",
    href: "https://fonts.googleapis.com/css?family=Permanent+Marker:regular&text=PermantMk",
  },
  {
    key: "playfair-display",
    name: "Playfair Display",
    href: "https://fonts.googleapis.com/css?family=Playfair+Display:regular&text=PlayfirDsp",
  },
  {
    key: "poppins",
    name: "Poppins",
    href: "https://fonts.googleapis.com/css?family=Poppins:regular&text=Popins",
  },
  {
    key: "prompt",
    name: "Prompt",
    href: "https://fonts.googleapis.com/css?family=Prompt:regular&text=Prompt",
  },
  {
    key: "pt-sans",
    name: "PT Sans",
    href: "https://fonts.googleapis.com/css?family=PT+Sans:regular&text=PTSans",
  },
  {
    key: "pt-sans-narrow",
    name: "PT Sans Narrow",
    href: "https://fonts.googleapis.com/css?family=PT+Sans+Narrow:regular&text=PTSansNrow",
  },
  {
    key: "pt-serif",
    name: "PT Serif",
    href: "https://fonts.googleapis.com/css?family=PT+Serif:regular&text=PTSerif",
  },
  {
    key: "questrial",
    name: "Questrial",
    href: "https://fonts.googleapis.com/css?family=Questrial:regular&text=Questrial",
  },
  {
    key: "quicksand",
    name: "Quicksand",
    href: "https://fonts.googleapis.com/css?family=Quicksand:regular&text=Quicksand",
  },
  {
    key: "rajdhani",
    name: "Rajdhani",
    href: "https://fonts.googleapis.com/css?family=Rajdhani:regular&text=Rajdhni",
  },
  {
    key: "raleway",
    name: "Raleway",
    href: "https://fonts.googleapis.com/css?family=Raleway:regular&text=Ralewy",
  },
  {
    key: "roboto",
    name: "Roboto",
    href: "https://fonts.googleapis.com/css?family=Roboto:regular&text=Robt",
  },
  {
    key: "roboto-condensed",
    name: "Roboto Condensed",
    href: "https://fonts.googleapis.com/css?family=Roboto+Condensed:regular&text=RobtCndes",
  },
  {
    key: "roboto-mono",
    name: "Roboto Mono",
    href: "https://fonts.googleapis.com/css?family=Roboto+Mono:regular&text=RobtMn",
  },
  {
    key: "roboto-slab",
    name: "Roboto Slab",
    href: "https://fonts.googleapis.com/css?family=Roboto+Slab:regular&text=RobtSla",
  },
  {
    key: "rubik",
    name: "Rubik",
    href: "https://fonts.googleapis.com/css?family=Rubik:regular&text=Rubik",
  },
  {
    key: "satisfy",
    name: "Satisfy",
    href: "https://fonts.googleapis.com/css?family=Satisfy:regular&text=Satisfy",
  },
  {
    key: "secular-one",
    name: "Secular One",
    href: "https://fonts.googleapis.com/css?family=Secular+One:regular&text=SecularOn",
  },
  {
    key: "shadows-into-light",
    name: "Shadows Into Light",
    href: "https://fonts.googleapis.com/css?family=Shadows+Into+Light:regular&text=ShadowsIntLig",
  },
  {
    key: "signika-negative",
    name: "Signika Negative",
    href: "https://fonts.googleapis.com/css?family=Signika+Negative:regular&text=SignkaNetv",
  },
  {
    key: "slabo-27px",
    name: "Slabo 27px",
    href: "https://fonts.googleapis.com/css?family=Slabo+27px:regular&text=Slabo27px",
  },
  {
    key: "source-code-pro",
    name: "Source Code Pro",
    href: "https://fonts.googleapis.com/css?family=Source+Code+Pro:regular&text=SourceCdP",
  },
  {
    key: "source-sans-pro",
    name: "Source Sans Pro",
    href: "https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular&text=SourceansP",
  },
  {
    key: "source-serif-pro",
    name: "Source Serif Pro",
    href: "https://fonts.googleapis.com/css?family=Source+Serif+Pro:regular&text=SourceifP",
  },
  {
    key: "teko",
    name: "Teko",
    href: "https://fonts.googleapis.com/css?family=Teko:regular&text=Teko",
  },
  {
    key: "titillium-web",
    name: "Titillium Web",
    href: "https://fonts.googleapis.com/css?family=Titillium+Web:regular&text=TitlumWeb",
  },
  {
    key: "ubuntu",
    name: "Ubuntu",
    href: "https://fonts.googleapis.com/css?family=Ubuntu:regular&text=Ubunt",
  },
  {
    key: "ubuntu-mono",
    name: "Ubuntu Mono",
    href: "https://fonts.googleapis.com/css?family=Ubuntu+Mono:regular&text=UbuntMo",
  },
  {
    key: "varela-round",
    name: "Varela Round",
    href: "https://fonts.googleapis.com/css?family=Varela+Round:regular&text=VarelRound",
  },
  {
    key: "work-sans",
    name: "Work Sans",
    href: "https://fonts.googleapis.com/css?family=Work+Sans:regular&text=WorkSans",
  },
  {
    key: "yanone-kaffeesatz",
    name: "Yanone Kaffeesatz",
    href: "https://fonts.googleapis.com/css?family=Yanone+Kaffeesatz:regular&text=YanoeKfstz",
  },
];
