import { BrowserRouter, Outlet } from "react-router-dom";
import { getFirestore } from "firebase/firestore";
import {
  FirestoreProvider,
  useFirebaseApp,
  AuthProvider,
  FunctionsProvider,
  StorageProvider,
} from "reactfire";
import { getAuth } from "firebase/auth";
import { AnimatedRoutes } from "./routes";
import { Navigation } from "./navigation/navigation";
import { UserDataContextProvider } from "./data/userDataContext";
import { AuthenticationContextProvider } from "./admin/authenticationContext";
import { AdminContextProviders } from "./admin/adminContextProviders";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

function App() {
  const app = useFirebaseApp();
  const storage = getStorage(app);
  const firestore = getFirestore(app);
  const functions = getFunctions(app, "europe-west3");
  const auth = getAuth(app);

  return (
    <FirestoreProvider sdk={firestore}>
      <StorageProvider sdk={storage}>
        <AuthProvider sdk={auth}>
          <FunctionsProvider sdk={functions}>
            <BrowserRouter>
              <AuthenticationContextProvider>
                <AdminContextProviders>
                  <UserDataContextProvider>
                    <AnimatedRoutes />
                    <Outlet />
                    <Navigation />
                  </UserDataContextProvider>
                </AdminContextProviders>
              </AuthenticationContextProvider>
            </BrowserRouter>
          </FunctionsProvider>
        </AuthProvider>
      </StorageProvider>
    </FirestoreProvider>
  );
}

export default App;
