import { collection, doc, query } from "firebase/firestore";
import { createContext, FunctionComponent, useContext } from "react";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocDataOnce,
} from "reactfire";
import { UserData } from "../data/userDataContext";
import { LoadingPlaceholder } from "../misc/loadingPlaceholder";

export interface AdminData {
  users: UserData[];
  numberOfUserViews?: {
    [key: string]: number;
  };
}

const AdminDataContext = createContext<AdminData | undefined>(undefined);

export const AdminDataContextProvider: FunctionComponent = ({ children }) => {
  const firestore = useFirestore();
  const usersCollection = collection(firestore, "users");
  const usersQuery = query(usersCollection);
  const { status: userStatus, data: users } = useFirestoreCollectionData(
    usersQuery,
    {
      idField: "id",
    }
  );

  const statsDoc = doc(firestore, `stats/users`);
  const { status: statStatus, data: stats } = useFirestoreDocDataOnce(statsDoc);

  const sortedUsers = ((users as UserData[]) || []).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  if ([userStatus, statStatus].includes("loading")) {
    return <LoadingPlaceholder />;
  }

  return (
    <AdminDataContext.Provider
      value={{ users: sortedUsers, numberOfUserViews: stats }}
    >
      {children}
    </AdminDataContext.Provider>
  );
};

export const useAdminDataContext = () => {
  const context = useContext(AdminDataContext);

  if (context === undefined) {
    throw new Error("useAdminDataContext was used outside of its Provider");
  }

  return context;
};
