import {
  Icon,
  IconButton,
  Input,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { HexAlphaColorPicker } from "react-colorful";
import { UseFormRegisterReturn } from "react-hook-form";
import { IoIosColorPalette } from "react-icons/io";

interface StyledColorInputProps {
  placeholder?: string;
  registration: UseFormRegisterReturn;
  setValue: (value: string) => void;
  getValue: () => string | undefined;
}

export const StyledColorInput: FunctionComponent<StyledColorInputProps> = ({
  placeholder,
  registration,
  setValue,
  getValue,
}) => {
  const [lazyValue, setLazyValue] = useState<string>();
  return (
    <>
      <Input
        type={"text"}
        placeholder={placeholder}
        borderColor="whiteAlpha.900"
        _hover={{
          borderColor: "whiteAlpha.900",
        }}
        {...registration}
      />
      <Popover onOpen={() => setLazyValue(getValue())}>
        <PopoverTrigger>
          <InputRightElement>
            <IconButton
              variant={"unstyled"}
              borderColor={"whiteAlpha.900"}
              borderRadius={0}
              borderLeftWidth={1}
              color="yellow.500"
              aria-label={`Farbauswahl öffnen`}
              icon={<Icon as={IoIosColorPalette} />}
            />
          </InputRightElement>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <HexAlphaColorPicker
            color={lazyValue}
            onChange={setValue}
            style={{ width: "auto" }}
          />
        </PopoverContent>
      </Popover>
    </>
  );
};
