import { PageTransition } from "../misc/pageTransition";
import { Logo, LogoVariant } from "../misc/logo";
import {
  Box,
  Flex,
  Select,
  Spacer,
  Link,
  Checkbox,
  Center,
  Button,
  useToast,
  Text,
} from "@chakra-ui/react";
import { FunctionComponent, memo, useState } from "react";
import { Abonement, useUserDataContext } from "../data/userDataContext";
import { SignOutLink } from "../misc/signOutLink";
import { SubHeading } from "../misc/subHeading";
import { MainHeading } from "../misc/mainHeading";
import { useAbos } from "../data/useAbos";
import { formatSubscriptionCost } from "../misc/formatSubscriptionCost";
import { formatSubscriptionStartDate } from "../misc/formatSubscriptionStartDate";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  useActivateAbo,
  useCancelAboChange,
  useChangeAbo,
} from "../data/functions";
import { formatSubscriptionDuration } from "../misc/formatSubscriptionDuration";

interface FormValues {
  abo: string;
}

const getAboNameAndInfos = (abo: Abonement) => {
  const numberOfModels = abo.models > 1 ? `${abo.models} Modelle` : "1 Modell";
  const costs = abo.monthly
    ? `CHF ${abo.monthly} / Monat`
    : `CHF ${abo.yearly} / Jahr`;

  if (abo.designEditor) {
    return `${abo.name} (${numberOfModels}, ${costs}, individuelles Design)`;
  }

  return `${abo.name} (${numberOfModels}, ${costs})`;
};

const CostsInternal: FunctionComponent = () => {
  const { register, handleSubmit } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user, updateUserData } = useUserDataContext();
  const { abos } = useAbos();
  const changeAbo = useChangeAbo();
  const activateAbo = useActivateAbo();
  const cancelAboChange = useCancelAboChange();
  const toast = useToast();

  const aboChangeAbo = abos.find((abo) => abo.id === user.aboChange?.aboId);

  const cancel = async () => {
    await cancelAboChange(user.id);
    await updateUserData();
  };

  const activate = async () => {
    setIsLoading(true);
    try {
      await activateAbo(user.id);
      await updateUserData();
    } catch (e) {
      toast({
        title: "Fehler",
        description: "Das Abo konnte nicht aktiviert werden.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true);
    try {
      await changeAbo(user.id, data.abo);
      await updateUserData();
    } catch (e) {
      toast({
        title: "Fehler",
        description: "Das Abo konnte nicht gewechselt werden.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageTransition>
      <Logo variant={LogoVariant.Small} />
      <MainHeading>Kosten</MainHeading>
      <Text>
        Gerne stellen wir dir auf dieser Seite die Fact's zu deinem aktuellen
        Abo zusammen.
        <br /> Möchtest du dein Abo Upgraden? Dann kannst du das direkt auf
        dieser Seite erledigen.
      </Text>

      <Box
        borderColor="whiteAlpha.900"
        overflow={"hidden"}
        borderWidth={1}
        borderRadius={5}
        marginTop={10}
        marginBottom={10}
        paddingTop={2}
        paddingBottom={2}
        paddingLeft={5}
        paddingRight={5}
      >
        <Flex>
          <Box>Aktuelles Abo:</Box>
          <Spacer />
          <Box>«{user?.abo?.name}»</Box>
        </Flex>
        <Flex>
          <Box>Gestartet am:</Box>
          <Spacer />
          <Box>{formatSubscriptionStartDate(user)}</Box>
        </Flex>
        <Flex>
          <Box>Laufzeit:</Box>
          <Spacer />
          <Box>{formatSubscriptionDuration(user)}</Box>
        </Flex>
        <Flex>
          <Box>Kosten:</Box>
          <Spacer />
          <Box>{formatSubscriptionCost(user)}</Box>
        </Flex>
      </Box>

      {user.abo?.demo && (
        <>
          <SubHeading>Demo Abo</SubHeading>
          <Text>
            Du hast ein kostenloses Demo-Abo. Klicke auf "Abo aktivieren", um
            dein Abo jetzt zu starten und die Demo-Hinweise zu entfernen.
          </Text>

          <br />

          <Center>
            <Button
              isLoading={isLoading}
              isActive={!isLoading}
              loadingText="Wird aktiviert..."
              onClick={() => activate()}
            >
              Abo aktivieren
            </Button>
          </Center>
        </>
      )}

      {!user.aboChange && !user.abo?.demo && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <SubHeading>Abo ändern</SubHeading>
          <Select
            {...register("abo")}
            disabled={isLoading}
            colorScheme="whiteAlpha"
            errorBorderColor="red.400"
            focusBorderColor="yellow.500"
            borderColor="whiteAlpha.900"
            _hover={{
              borderColor: "whiteAlpha.900",
            }}
            placeholder="Wähle dein Abo"
            marginBottom={5}
            required
          >
            {abos
              .filter((abo) => abo.name !== user.abo?.name)
              .map((abo) => (
                <option key={abo.id} value={abo.id}>
                  {getAboNameAndInfos(abo)}
                </option>
              ))}
            <option value="0">Abo künden</option>
          </Select>

          <Link href="https://3d-tool.ch/#ABOS" target={"_blank"} isExternal>
            zu den Abos und Preisen
          </Link>

          <br />
          <br />

          <Checkbox
            disabled={isLoading}
            colorScheme="yellow"
            borderColor="yellow.500"
            required
          >
            Ich habe die{" "}
            <Link
              href="https://3d-tool.ch/impressum/"
              target={"_blank"}
              isExternal
            >
              AGB's
            </Link>{" "}
            gelesen und bin damit einverstanden.
          </Checkbox>

          <br />
          <br />

          <Center>
            <Button
              isLoading={isLoading}
              isActive={!isLoading}
              loadingText="Wird gespeichert..."
              variant={"outline"}
              color="yellow.500"
              background="black"
              borderColor="yellow.500"
              _active={{ bg: "inherit" }}
              _hover={{ bg: "yellow.500", color: "black" }}
              type="submit"
            >
              Speichern
            </Button>
          </Center>
        </form>
      )}

      {user.aboChange && (
        <>
          <SubHeading>Abo ändern</SubHeading>
          <Box
            borderColor="whiteAlpha.900"
            overflow={"hidden"}
            borderWidth={1}
            borderRadius={5}
            paddingTop={2}
            paddingBottom={2}
            paddingLeft={5}
            paddingRight={5}
          >
            <Flex>
              {user.aboChange.aboId === "0" && <Box>Abo wird gekündigt.</Box>}
              {aboChangeAbo && (
                <>
                  <Box>Zukünftiges Abo:</Box>
                  <Spacer />
                  <Box>«{aboChangeAbo.name}»</Box>
                </>
              )}
            </Flex>
            <Link onClick={() => cancel()}>Abbrechen</Link>
          </Box>
        </>
      )}

      <br />
      <br />
      <SignOutLink />
      <br />
      <br />
    </PageTransition>
  );
};

export const Costs = memo(CostsInternal, (_prevProps, _nextProps) => false);
