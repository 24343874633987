import { Stack } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
import { StyledFormControl } from "../../form/styledFormControl";
import { StyledPasswordInput } from "../../form/styledPasswordInput";
import { StyledTextInput } from "../../form/styledTextInput";

export type ProfileAccountValues = {
  email?: string;
  password?: string;
};

interface ProfileAccountFormProps {
  registration: UseFormRegisterReturn;
  autoComplete?: string;
  isLoading: boolean;
}

export const ProfileEmailForm: FunctionComponent<ProfileAccountFormProps> = ({
  registration,
  isLoading,
  autoComplete,
}) => {
  return (
    <Stack spacing={3}>
      <StyledFormControl
        input={
          <StyledTextInput
            type="text"
            autoComplete={autoComplete}
            registration={registration}
            placeholder={"Neuer Benutzername / E-Mail"}
          />
        }
        isDisabled={isLoading}
      />
    </Stack>
  );
};

export const ProfilePassordForm: FunctionComponent<ProfileAccountFormProps> = ({
  registration,
  isLoading,
  autoComplete,
}) => {
  return (
    <Stack spacing={3}>
      <StyledFormControl
        input={
          <StyledPasswordInput
            autoComplete={autoComplete}
            registration={registration}
            placeholder={"Neues Passwort"}
          />
        }
        isDisabled={isLoading}
      />
    </Stack>
  );
};
