import { Button, Stack, Text, Link, Center } from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import {
  Location,
  useLocation,
  Link as RouterLink,
  Navigate,
} from "react-router-dom";
import { useAuth } from "reactfire";
import { PageTransition } from "../misc/pageTransition";
import { StyledFormControl } from "../form/styledFormControl";
import { Logo, LogoVariant } from "../misc/logo";
import { StyledTextInput } from "../form/styledTextInput";
import { StyledPasswordInput } from "../form/styledPasswordInput";
import { SubmitHandler, useForm } from "react-hook-form";

type FormValues = {
  email: string;
  password: string;
};

export const Login = () => {
  const auth = useAuth();
  const location = useLocation();

  const { register, handleSubmit } = useForm<FormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasError, setHasError] = useState(false);

  const { from } =
    (location.state as {
      from: Location | undefined;
    }) || {};

  const onSubmit: SubmitHandler<FormValues> = async ({ email, password }) => {
    setIsLoading(true);
    try {
      setHasError(false);
      await signInWithEmailAndPassword(auth, email, password);
    } catch (e) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (auth.currentUser) {
    // redirect if user is logged-in...
    return <Navigate to={from?.pathname || "/"} replace />;
  }

  return (
    <PageTransition>
      <Logo variant={LogoVariant.Big} />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3}>
          <StyledFormControl
            input={
              <StyledTextInput
                type="email"
                placeholder="E-Mail"
                registration={register("email")}
              />
            }
            isInvalid={hasError}
            isDisabled={isLoading}
            isRequired
          />

          <StyledFormControl
            input={
              <StyledPasswordInput
                placeholder="Passwort"
                registration={register("password")}
              />
            }
            isInvalid={hasError}
            isDisabled={isLoading}
            isRequired
          />
        </Stack>

        <br />

        <Center>
          <Button
            isLoading={isLoading}
            isActive={!isLoading}
            loadingText="Wird geladen..."
            type="submit"
          >
            Login
          </Button>
        </Center>

        <br />

        <Text textAlign={"center"}>
          <Link as={RouterLink} to="/reset-password">
            Passwort vergessen?
          </Link>
        </Text>
      </form>
    </PageTransition>
  );
};
