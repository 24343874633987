import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSigninCheck } from "reactfire";
import { LoadingPlaceholder } from "../misc/loadingPlaceholder";

export interface AuthenticationState {
  isLoggedIn: boolean;
  isAdmin: boolean;
  loggedInUserId?: string;
}

const AuthenticationContext = createContext<AuthenticationState | undefined>(
  undefined
);

export const AuthenticationContextProvider: FunctionComponent = ({
  children,
}) => {
  const { status, data: signinCheck } = useSigninCheck();
  const [state, setState] = useState<AuthenticationState>();

  useEffect(() => {
    if (status !== "loading") {
      if (signinCheck.signedIn) {
        signinCheck.user?.getIdTokenResult().then((token) => {
          setState({
            isLoggedIn: true,
            isAdmin: Boolean(token.claims.admin),
            loggedInUserId: signinCheck.user.uid,
          });
        });
      } else {
        setState({
          isLoggedIn: false,
          isAdmin: false,
        });
      }
    }
  }, [
    signinCheck?.user,
    signinCheck?.user?.uid,
    signinCheck?.signedIn,
    status,
  ]);

  if (!state) {
    return <LoadingPlaceholder />;
  }

  return (
    <AuthenticationContext.Provider value={state}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export const useAuthenticationContext = () => {
  const context = useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error(
      "useAuthenticationContext was used outside of its Provider"
    );
  }

  return context;
};
