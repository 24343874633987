import { Button, Center, Stack, useToast } from "@chakra-ui/react";
import { deleteObject, ref, uploadBytes } from "firebase/storage";
import { FunctionComponent, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useFirestore, useStorage } from "reactfire";
import { useSelectedUserId } from "../../admin/useSelectedUserId";
import { useUserDataContext } from "../../data/userDataContext";
import { StyledFileInput } from "../../form/styledFileInput";
import { StyledFormControl } from "../../form/styledFormControl";
import { StyledSelectInput } from "../../form/styledSelectInput";
import { StyledTextArea } from "../../form/styledTextArea";
import { StyledTextInput } from "../../form/styledTextInput";
import { v4 as uuidv4 } from "uuid";
import {
  addDoc,
  collection,
  doc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Model } from "../../data/types/model";

type FormValues = {
  name: string;
  glb: FileList | null;
  usdz: FileList | null;
  png: FileList | null;
  text: string;
  placement: string;
};

export const ModelForm: FunctionComponent<{
  model?: Model;
}> = ({ model }) => {
  const { register, watch, resetField, setValue, handleSubmit } =
    useForm<FormValues>({
      defaultValues: {
        name: model?.name,
        text: model?.text,
        placement: model?.placement,
      },
    });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { selectedUserId } = useSelectedUserId();
  const { updateUserData, user, models } = useUserDataContext();
  const formRef = useRef<HTMLFormElement>(null);
  const navigate = useNavigate();
  const firestore = useFirestore();
  const storage = useStorage();
  const toast = useToast();

  const availableSlots = user?.abo?.models || 0;
  const activeModels = models.filter((m) => !m.disabled);
  const remainingSlots = availableSlots - activeModels.length;
  const modelRef = doc(firestore, `models/${model?.id}`);

  const archive = async () => {
    setIsLoading(true);
    await updateDoc(modelRef, { disabled: true });
    await updateUserData();
    setIsLoading(false);
  };

  const activate = async () => {
    setIsLoading(true);
    await updateDoc(modelRef, { disabled: false });
    await updateUserData();
    setIsLoading(false);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const updatedModelData: {
      name: string;
      text: string;
      ts?: Timestamp;
      glb?: { file: string; name: string } | null;
      usdz?: { file: string; name: string } | null;
      png?: { file: string; name: string } | null;
      placement: string;
      userId?: string;
    } = {
      name: data.name,
      text: data.text,
      placement: data.placement,
      userId: selectedUserId,
    };

    setIsLoading(true);

    // upload glb file
    if (data.glb && data.glb.length > 0) {
      const glbFile = data.glb[0];
      const glbStorageName = model?.glb ? model.glb.file : `${uuidv4()}.glb`;
      try {
        await uploadBytes(
          ref(storage, `contents/${selectedUserId}/${glbStorageName}`),
          glbFile
        );
        updatedModelData.glb = { file: glbStorageName, name: glbFile.name };
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${glbFile.name} konnte nicht hochgeladen werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // remove glb file
    if (data.glb === null && model?.glb) {
      try {
        await deleteObject(
          ref(storage, `contents/${selectedUserId}/${model.glb.file}`)
        );
        updatedModelData.glb = null;
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${model.glb.name} konnte nicht gelöscht werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // upload usdz file
    if (data.usdz && data.usdz.length > 0) {
      const usdzFile = data.usdz[0];
      const usdzStorageName = model?.usdz
        ? model.usdz.file
        : `${uuidv4()}.usdz`;
      try {
        await uploadBytes(
          ref(storage, `contents/${selectedUserId}/${usdzStorageName}`),
          usdzFile
        );
        updatedModelData.usdz = {
          file: usdzStorageName,
          name: usdzFile.name,
        };
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${usdzFile.name} konnte nicht hochgeladen werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // remove usdz file
    if (data.usdz === null && model?.usdz) {
      try {
        await deleteObject(
          ref(storage, `contents/${selectedUserId}/${model.usdz.file}`)
        );
        updatedModelData.usdz = null;
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${model.usdz.name} konnte nicht gelöscht werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // upload png file
    if (data.png && data.png.length > 0) {
      const pngFile = data.png[0];
      const pngStorageName = model?.png ? model.png.file : `${uuidv4()}.png`;
      try {
        await uploadBytes(
          ref(storage, `contents/${selectedUserId}/${pngStorageName}`),
          pngFile
        );
        updatedModelData.png = {
          file: pngStorageName,
          name: pngFile.name,
        };
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${pngFile.name} konnte nicht hochgeladen werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // remove png file
    if (data.png === null && model?.png) {
      try {
        await deleteObject(
          ref(storage, `contents/${selectedUserId}/${model.png.file}`)
        );
        updatedModelData.png = null;
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: `${model.png.name} konnte nicht gelöscht werden.`,
          status: "error",
          position: "top",
        });
      }
    }

    // add new model
    if (!model) {
      updatedModelData.ts = Timestamp.now();
      try {
        const addedModel = await addDoc(
          collection(firestore, "models"),
          updatedModelData
        );
        navigate(`/models/edit/${addedModel.id}`);
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: "Modell konnte nicht erstellt werden.",
          status: "error",
          position: "top",
        });
      }
    }

    // edit model
    else {
      try {
        await updateDoc(doc(firestore, `models/${model.id}`), updatedModelData);
      } catch {
        setIsLoading(false);
        return toast({
          title: "Fehler",
          description: "Modell konnte nicht bearbeitet werden.",
          status: "error",
          position: "top",
        });
      }
    }

    // reset input fields
    await updateUserData();
    setIsLoading(false);
    toast({
      title: model ? "Modell wurde aktualisiert" : "Modell wurde erstellt",
      status: "success",
      position: "top",
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
      <Stack spacing={3}>
        <StyledFormControl
          title="Name (wird nach aussen nicht angezeigt)"
          input={
            <StyledTextInput type="text" registration={register("name")} />
          }
          isDisabled={isLoading}
          isRequired
        />
        <StyledFormControl
          title="3D-Modell Android (GLB)"
          input={
            <StyledFileInput
              acceptedFileTypes={".glb"}
              registration={register("glb")}
              isDisabled={isLoading}
              placeholder={model?.glb?.name}
              clearFn={() => {
                resetField("glb");
                setValue("glb", null);
              }}
              value={watch("glb")}
            />
          }
          isDisabled={isLoading}
        />
        <StyledFormControl
          title="3D-Modell iOS Apple (USDZ)"
          input={
            <StyledFileInput
              acceptedFileTypes={".usdz"}
              registration={register("usdz")}
              isDisabled={isLoading}
              placeholder={model?.usdz?.name}
              clearFn={() => {
                resetField("usdz");
                setValue("usdz", null);
              }}
              value={watch("usdz")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <StyledFormControl
          title="Vorschaubild (PNG)"
          input={
            <StyledFileInput
              acceptedFileTypes={".png"}
              registration={register("png")}
              isDisabled={isLoading}
              placeholder={model?.png?.name}
              clearFn={() => {
                resetField("png");
                setValue("png", null);
              }}
              value={watch("png")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <StyledFormControl
          title="Beschreibung"
          input={<StyledTextArea registration={register("text")} />}
          isDisabled={isLoading}
        />

        <StyledFormControl
          title="Plazierung"
          input={
            <StyledSelectInput registration={register("placement")}>
              <option value="floor">Am Boden / Auf Fläche</option>
              <option value="wall">An der Wand</option>
            </StyledSelectInput>
          }
          isDisabled={isLoading}
          isRequired
        />
      </Stack>

      <br />
      <br />

      <Center>
        <Button
          isLoading={isLoading}
          isActive={!isLoading}
          loadingText={"Wird gespeichert..."}
          type={"submit"}
        >
          {model ? "Änderungen speichern" : "Modell hochladen"}
        </Button>
      </Center>

      {model && !model.disabled && (
        <>
          <br />
          <Center>
            <Button
              isLoading={isLoading}
              isActive={!isLoading}
              loadingText={"Wird archiviert..."}
              onClick={() => archive()}
            >
              {"Modell archivieren"}
            </Button>
          </Center>
        </>
      )}

      {model && model.disabled && remainingSlots > 0 && (
        <>
          <br />
          <Center>
            <Button
              isLoading={isLoading}
              isActive={!isLoading}
              loadingText={"Wird aktiviert..."}
              onClick={() => activate()}
            >
              {"Modell aktivieren"}
            </Button>
          </Center>
        </>
      )}
    </form>
  );
};
