import { ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { IconButton, Input, InputRightElement } from "@chakra-ui/react";
import { FunctionComponent, useState } from "react";
import { UseFormRegisterReturn } from "react-hook-form";

interface StyledPasswordInputProps {
  placeholder?: string;
  registration: UseFormRegisterReturn;
  autoComplete?: string;
}

export const StyledPasswordInput: FunctionComponent<
  StyledPasswordInputProps
> = ({ placeholder, registration, autoComplete }) => {
  const [showPassword, setShowPassword] = useState(false);
  const type = showPassword ? "text" : "password";

  const handleToggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <Input
        type={type}
        colorScheme="whiteAlpha"
        errorBorderColor="red.400"
        focusBorderColor="yellow.500"
        placeholder={placeholder}
        autoComplete={autoComplete}
        borderColor="whiteAlpha.900"
        _hover={{
          borderColor: "whiteAlpha.900",
        }}
        {...registration}
      />
      <InputRightElement>
        <IconButton
          variant={"unstyled"}
          color="yellow.500"
          aria-label={
            showPassword ? "Passwort verstecken" : "Passwort anzeigen"
          }
          icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
          onClick={handleToggleShowPassword}
        />
      </InputRightElement>
    </>
  );
};
