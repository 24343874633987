import { UserData } from "../data/userDataContext";
import { de } from "date-fns/locale";
import { format } from "date-fns";

export const formatSubscriptionStartDate = (user: UserData): string => {
  if (!user.abo?.ts) {
    return "-";
  }

  return format(user.abo.ts.toDate(), "dd. MMMM yyyy", { locale: de });
};
