import "@google/model-viewer";

import { FunctionComponent, ReactNode } from "react";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": {
        src: string;
        "ios-src": string;
        alt: string;
        "camera-controls"?: boolean;
        ar: boolean;
        style?: {};
        loading: string;
        poster?: string;
        autoplay: boolean;
        children?: ReactNode;
      };
    }
  }
}

export const ModelViewer: FunctionComponent<{
  name: string;
  posterColor: string;
  posterSrc?: string;
  glbSrc: string;
  usdzSrc: string;
  arbutton: ReactNode;
  placement: "floor" | "wall";
}> = ({
  name,
  glbSrc,
  usdzSrc,
  posterColor,
  arbutton,
  placement,
  posterSrc,
}) => (
  <model-viewer
    style={{
      height: "100%",
      width: "100%",
      "--poster-color": posterColor,
    }}
    src={glbSrc}
    ios-src={usdzSrc}
    alt={name}
    ar
    loading="lazy"
    ar-placement={placement}
    camera-controls
    poster={posterSrc}
    autoplay
  >
    <button
      slot="ar-button"
      style={{
        position: "absolute",
        zIndex: 100,
        left: "50%",
        bottom: "22px",
        marginLeft: "-25px",
        width: "50px",
        height: "50px",
      }}
    >
      {arbutton}
    </button>
  </model-viewer>
);
