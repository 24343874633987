import { createUserWithEmailAndPassword } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useAuth, useFunctions } from "reactfire";

/**
 * Track model views
 */
export const useTrackView = () => {
  const functions = useFunctions();
  const trackView = httpsCallable(functions, "trackView");

  return async function (modelId: string, src: "widget" | "app") {
    return trackView({
      modelId,
      src,
    });
  };
};

/**
 * Abo change request
 */
export const useChangeAbo = () => {
  const functions = useFunctions();
  const changeAbo = httpsCallable<unknown, { ok: boolean; err?: string }>(
    functions,
    "saveUserData"
  );

  return async function (userId: string, aboId: string) {
    return changeAbo({
      uid: userId,
      aboId: aboId,
    });
  };
};

/**
 * Cancel abo change request
 */
export const useCancelAboChange = () => {
  const functions = useFunctions();
  const changeAbo = httpsCallable<unknown, { ok: boolean; err?: string }>(
    functions,
    "saveUserData"
  );

  return async function (userId: string) {
    return changeAbo({
      uid: userId,
      aboChange: false,
    });
  };
};

/**
 * Activate abo
 */
export const useActivateAbo = () => {
  const functions = useFunctions();
  const changeAbo = httpsCallable<unknown, { ok: boolean; err?: string }>(
    functions,
    "saveUserData"
  );

  return async function (userId: string) {
    return changeAbo({
      uid: userId,
      activate: true,
    });
  };
};

/**
 * Activate user
 */
export const useActivateUser = () => {
  const functions = useFunctions();
  const activateUser = httpsCallable<unknown, { ok: boolean; err?: string }>(
    functions,
    "saveUser"
  );

  return async function (userId: string) {
    return activateUser({
      uid: userId,
      disabled: false,
    });
  };
};

/**
 * De-Activate user
 */
export const useDeActivateUser = () => {
  const functions = useFunctions();
  const deActivateUser = httpsCallable<unknown, { ok: boolean; err?: string }>(
    functions,
    "saveUser"
  );

  return async function (userId: string) {
    return deActivateUser({
      uid: userId,
      disabled: true,
    });
  };
};

/**
 * Save selected user's profile data (admin-only)
 * Handles some things slightly different (e.g. abo changes happen immediately)
 */
export const useAdminSaveUser = () => {
  const functions = useFunctions();
  const saveUser = httpsCallable<
    unknown,
    { ok: boolean; err?: string; uid?: string }
  >(functions, "saveUser");

  return async function (userId: string | undefined, data: UpsertableUserData) {
    return saveUser({
      ...data,
      uid: userId,
    });
  };
};

/**
 * Save own or selected user's profile data
 */
export const useSaveUserData = () => {
  const functions = useFunctions();
  const saveUserData = httpsCallable<unknown, { ok: boolean; err?: string }>(
    functions,
    "saveUserData"
  );

  return async function (userId: string | undefined, data: UpsertableUserData) {
    return saveUserData({
      ...data,
      uid: userId,
    });
  };
};

/**
 * Registration
 */
export const useSignUp = () => {
  const functions = useFunctions();
  const saveUserData = httpsCallable(functions, "saveUserData");
  const auth = useAuth();

  return async function (data: {
    name: string;
    aboId: string;
    email: string;
    password: string;
    company: string;
    address: string;
    plzcity: string;
    phone: string;
  }) {
    const credentials = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );

    return saveUserData({
      ...data,
      uid: credentials.user.uid,
      instance: "tool",
    });
  };
};

export interface UpsertableUserData {
  design: {
    bgImg?: { file: string; name: string } | null;
    bgColor?: string;
    borderColor?: string;
    font?: string;
    logo?: { file: string; name: string } | null;
    mainColor?: string;
    text?: string;
    textColor?: string;
    url?: string;
  };
  name?: string;
  aboId?: string;
  email?: string;
  password?: string;
  company?: string;
  address?: string;
  plzcity?: string;
  phone?: string;
}
