import { FormControl, InputGroup } from "@chakra-ui/react";
import { FunctionComponent, ReactElement } from "react";
import { OverflowText } from "../misc/overflowText";

interface FormControlInputProps {
  input: ReactElement;
  title?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
}

export const StyledFormControl: FunctionComponent<FormControlInputProps> = ({
  title,
  isRequired,
  isInvalid,
  isDisabled,
  input,
}) => (
  <FormControl
    isRequired={isRequired}
    isInvalid={isInvalid}
    isDisabled={isDisabled}
  >
    {title && (
      <OverflowText fontSize="xs" paddingBottom={1}>
        {title}
      </OverflowText>
    )}
    <InputGroup>{input}</InputGroup>
  </FormControl>
);
