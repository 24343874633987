import { Stack } from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { StyledFormControl } from "../../form/styledFormControl";
import { StyledTextArea } from "../../form/styledTextArea";
import { StyledTextInput } from "../../form/styledTextInput";
import { MainHeading } from "../../misc/mainHeading";
import { SubHeading } from "../../misc/subHeading";

interface FormValues {
  title: string;
  text: string;
  person_name_1: string;
  person_contact_1: string;
  person_image_1: string;
  person_name_2: string;
  person_contact_2: string;
  person_image_2: string;
}

export const EditSupport = () => {
  const { register } = useForm<FormValues>();
  const [isLoading] = useState<boolean>(false);
  return (
    <>
      <MainHeading>Support</MainHeading>
      <Stack spacing={3}>
        <StyledFormControl
          input={
            <StyledTextInput
              type="text"
              placeholder="Titel"
              registration={register("title")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <StyledFormControl
          input={
            <StyledTextArea
              placeholder="Text"
              registration={register("text")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <br />

        <SubHeading>1. Kontakt</SubHeading>
        <StyledFormControl
          input={
            <StyledTextInput
              type="text"
              placeholder="Titel"
              registration={register("person_name_1")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <StyledFormControl
          input={
            <StyledTextInput
              type="text"
              placeholder="Telefon/E-Mail"
              registration={register("person_contact_1")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <StyledFormControl
          input={
            <StyledTextInput
              type="text"
              placeholder="Bild"
              registration={register("person_image_1")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <br />

        <SubHeading>2. Kontakt</SubHeading>
        <StyledFormControl
          input={
            <StyledTextInput
              type="text"
              placeholder="Titel"
              registration={register("person_name_2")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <StyledFormControl
          input={
            <StyledTextInput
              type="text"
              placeholder="Telefon/E-Mail"
              registration={register("person_contact_2")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
        <StyledFormControl
          input={
            <StyledTextInput
              type="text"
              placeholder="Bild"
              registration={register("person_image_2")}
            />
          }
          isDisabled={isLoading}
          isRequired
        />
      </Stack>
    </>
  );
};
