import { MainHeading } from "../../misc/mainHeading";
import { PageTransition } from "../../misc/pageTransition";
import { Text } from "@chakra-ui/react";
import { Logo, LogoVariant } from "../../misc/logo";
import { ModelForm } from "./modelForm";

export const ModelNew = () => {
  return (
    <PageTransition>
      <Logo variant={LogoVariant.Small} />
      <MainHeading>Model hochladen</MainHeading>

      <Text>
        Um dein AR-Modell auf möglichst vielen Devices darstellen zu können
        benötigt es zwei verschiedene Dateiformate. Das Vorschaubild ermöglicht
        dir eine zusätzliche Optimierung des ersten Eindrucks.
      </Text>

      <br />
      <br />

      <ModelForm />

      <br />
      <br />
    </PageTransition>
  );
};
