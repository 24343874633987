import { FunctionComponent } from "react";
import { useAuthenticationContext } from "./authenticationContext";

export const AdminContent: FunctionComponent = ({ children }) => {
  const { isAdmin } = useAuthenticationContext();

  if (!isAdmin) {
    return null;
  }

  return <>{children}</>;
};
