import icon from "../assets/img/home.svg";
import iconActive from "../assets/img/home_active.svg";

import { AspectRatio, Image } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { FunctionComponent } from "react";

interface LinkHomeProps {
  currentPosition: number | null;
  position: number;
}

export const LinkHome: FunctionComponent<LinkHomeProps> = ({
  currentPosition,
  position,
}) => {
  const location = useLocation();
  const active = location.pathname === "/";

  return (
    <Link to="/" state={{ current: currentPosition, position: position }}>
      <AspectRatio ratio={18 / 25} width={50}>
        <Image src={active ? iconActive : icon} alt="Home" userSelect="none" />
      </AspectRatio>
    </Link>
  );
};
