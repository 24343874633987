import { CopyIcon, LinkIcon } from "@chakra-ui/icons";
import {
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
  Center,
  Link,
  Box,
  Heading,
  Input,
  useToast,
  useClipboard,
  Flex,
} from "@chakra-ui/react";
import { FunctionComponent, useRef } from "react";
import { SubHeading } from "../../misc/subHeading";
import { QRCodeCanvas } from "qrcode.react";
import { Model } from "../../data/types/model";
import { useUserDataContext } from "../../data/userDataContext";

const downloadQRCode = (canvas: HTMLCanvasElement, name: string) => {
  const pngUrl = canvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  let downloadLink = document.createElement("a");
  downloadLink.href = pngUrl;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const ModelShareButton: FunctionComponent<{ model: Model }> = ({
  model,
}) => {
  const url = `https://app.3d-tool.ch/v/${model.id}`;
  const html = `<script src="https://app.3d-tool.ch/ar-widget.min.js"></script><ar-widget model-id="${model.id}"></ar-widget>`;

  const toast = useToast();
  const { onCopy: copyUrl } = useClipboard(url);
  const { onCopy: copyHtml } = useClipboard(html);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useUserDataContext();
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <IconButton
        aria-label={`Share ${model.name}`}
        variant={"outline"}
        color="yellow.500"
        size={"sm"}
        borderColor="whiteAlpha.900"
        _hover={{
          borderColor: "whiteAlpha.900",
        }}
        onClick={onOpen}
        icon={<LinkIcon />}
      />

      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        motionPreset="slideInBottom"
        size={"xl"}
      >
        <ModalOverlay background={"blackAlpha.800"} />
        <Box padding={10}>
          <ModalContent
            backgroundColor={"black"}
            paddingTop={5}
            paddingBottom={5}
          >
            <ModalCloseButton />
            <ModalBody>
              <Heading size={"lg"}>Modell Publizieren</Heading>
              <SubHeading as="h3">QR-Code</SubHeading>

              <Box
                onClick={() =>
                  downloadQRCode(
                    containerRef.current?.querySelector(
                      "canvas"
                    ) as HTMLCanvasElement,
                    `${model.name}.png`
                  )
                }
              >
                <Center
                  display={"inline-block"}
                  background={"white"}
                  padding={5}
                  ref={containerRef}
                >
                  <QRCodeCanvas
                    value={url}
                    size={1500}
                    style={{ maxWidth: 180, maxHeight: 180 }}
                  />
                </Center>
                <br />
                <Link>QR-Code herunterladen</Link>
              </Box>

              <SubHeading as="h3">Link</SubHeading>
              <Flex mb={2}>
                <Input
                  value={url}
                  isReadOnly
                  borderColor="whiteAlpha.900"
                  _hover={{
                    borderColor: "whiteAlpha.900",
                  }}
                />
                <IconButton
                  variant={"outline"}
                  color="yellow.500"
                  aria-label={`Url kopieren`}
                  borderColor="whiteAlpha.900"
                  _hover={{
                    borderColor: "whiteAlpha.900",
                  }}
                  icon={<CopyIcon />}
                  onClick={() => {
                    copyUrl();
                    toast({
                      title: "URL kopiert",
                      status: "success",
                      isClosable: true,
                      position: "top",
                    });
                  }}
                  ml={2}
                />
              </Flex>

              {user.abo?.widgets && (
                <>
                  <SubHeading as="h3">Einbetten (HTML)</SubHeading>
                  <Flex mb={2}>
                    <Input
                      value={html}
                      isReadOnly
                      borderColor="whiteAlpha.900"
                      _hover={{
                        borderColor: "whiteAlpha.900",
                      }}
                    />
                    <IconButton
                      variant={"outline"}
                      color="yellow.500"
                      aria-label={`HTML kopieren`}
                      borderColor="whiteAlpha.900"
                      _hover={{
                        borderColor: "whiteAlpha.900",
                      }}
                      icon={<CopyIcon />}
                      onClick={() => {
                        copyHtml();
                        toast({
                          title: "HTML kopiert",
                          status: "success",
                          isClosable: true,
                          position: "top",
                        });
                      }}
                      ml={2}
                    />
                  </Flex>
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </>
  );
};
